import React, { useState, useEffect, useMemo, useRef } from "react";
import { Table, Modal, Form, Tabs, Tab, Spinner } from 'react-bootstrap';
import PRMTSService from '../../app/service/prmtsService';
import TaxesReferenceService from "../../app/service/taxeReferenceService";
import { messageError, messageSuccess } from '../../components/toastr';
import { Button } from 'primereact/button';

/**
 * Opções de meses para o select
 */
const monthOptions = [
    { label: 'Janeiro', value: 1 },
    { label: 'Fevereiro', value: 2 },
    { label: 'Março', value: 3 },
    { label: 'Abril', value: 4 },
    { label: 'Maio', value: 5 },
    { label: 'Junho', value: 6 },
    { label: 'Julho', value: 7 },
    { label: 'Agosto', value: 8 },
    { label: 'Setembro', value: 9 },
    { label: 'Outubro', value: 10 },
    { label: 'Novembro', value: 11 },
    { label: 'Dezembro', value: 12 }
];

/**
 * Formatação de moeda (R$ XX,XX)
 */
const formatCurrency = (value) => {
    if (value === '' || value === null || value === undefined) return '';
    const number = typeof value === 'string'
        ? parseFloat(value.replace(/[R$\s.]/g, '').replace(',', '.'))
        : value;
    if (isNaN(number)) return '';
    return Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(number);
};

/**
 * Parser para converter string monetária em number
 */
const parseCurrency = (value) => {
    if (value === '' || value === null || value === undefined) return '';
    const number = typeof value === 'string'
        ? parseFloat(value.replace(/[R$\s.]/g, '').replace(',', '.'))
        : value;
    if (isNaN(number)) return '';
    return number;
};

const Taxes = ({
    taxes,
    editedTaxes,
    onHandleChange,
    onYearChange
}) => {
    const prmtsService = useMemo(() => new PRMTSService(), []);
    const taxesReferenceService = useMemo(() => new TaxesReferenceService(), []);

    // Modal de edição de ano (opcional)
    const [showModal, setShowModal] = useState(false);
    const [yearTaxes, setYearTaxes] = useState('');

    // Lista de anos disponíveis (ordenados em ordem crescente)
    const [availableYears, setAvailableYears] = useState([]);

    // Ano atual (aba ativa padrão)
    const currentYear = new Date().getFullYear();
    const [activeYear, setActiveYear] = useState(currentYear);

    // Armazena as taxas divididas por ano: { 2023: [...], 2024: [...] }
    const [taxesByYear, setTaxesByYear] = useState({});

    // Edições pendentes, indexadas por ano: { 2023: { [taxId]: {...} }, 2024: {...} }
    const [editedTaxesByYear, setEditedTaxesByYear] = useState({});

    // Campo em foco e valor original (para manipular currency)
    const [focusedField, setFocusedField] = useState(null);
    const [originalValue, setOriginalValue] = useState({});

    // Valores calculados das parcelas, por ano e tax.id
    const [parcelValuesByYear, setParcelValuesByYear] = useState({});

    // Para scroll lateral das abas
    const tabsContainerRef = useRef(null);
    const scrollAmount = 100;

    // Loading local (spinner enquanto busca dados)
    const [localLoading, setLocalLoading] = useState(false);

    /**
     * Ao montar, busca os anos disponíveis e carrega o ano atual
     */
    useEffect(() => {
        const loadYears = async () => {
            try {
                setLocalLoading(true);

                // Busca todas as referências para descobrir anos existentes
                const response = await taxesReferenceService.getAll();
                if (response.data) {
                    const allRefs = response.data;
                    const distinctYears = [
                        ...new Set(allRefs.map((ref) => ref.year))
                    ].filter(Boolean);
                    distinctYears.sort((a, b) => a - b);

                    // Inclui o ano atual se não estiver na lista
                    if (!distinctYears.includes(currentYear)) {
                        distinctYears.push(currentYear);
                        distinctYears.sort((a, b) => a - b);
                    }
                    setAvailableYears(distinctYears);

                    // Carrega as taxas do ano atual
                    await fetchTaxesByYear(currentYear);
                }
            } catch (error) {
                console.error(error);
                messageError("Erro ao carregar anos disponíveis.");
            } finally {
                setLocalLoading(false);
            }
        };

        loadYears();
        // eslint-disable-next-line
    }, []);

    /**
     * Busca as referências do back-end para um dado 'year' e mescla com 'taxes' do props,
     * exibindo sempre o registro mais recente (valor mais atualizado).
     */
    const fetchTaxesByYear = async (year) => {
        try {
            setLocalLoading(true);
            // Busca todas as references para aquele ano
            const response = await taxesReferenceService.getByYear(year);
            const refs = response.data || [];

            // Pega a lista de taxes que vem do props (ou array vazio, se não existir)
            const allTaxes = (props => props.taxes || [])({ taxes });
            // Para cada taxa, filtra as references do back-end e pega a mais recente
            const merged = allTaxes.map((t) => {
                const allRefsForThisTax = refs
                    .filter((r) => r.tax?.id === t.id)
                    .sort((a, b) => new Date(b.dtReg) - new Date(a.dtReg));
                const refFound = allRefsForThisTax[0];
                return refFound
                    ? { ...t, value: refFound.value, year: refFound.year }
                    : { ...t, value: 0, year };
            });
            // Calcula valor inicial das parcelas
            const initialParcel = {};
            merged.forEach((m) => {
                const valNum = parseCurrency(m.value);
                const inst = parseInt(m.installments, 10) || 1;
                initialParcel[m.id] = inst > 0 ? formatCurrency(valNum / inst) : '';
            });
            // Armazena no estado
            setTaxesByYear((prev) => ({
                ...prev,
                [year]: merged,
            }));
            // Garante que no editedTaxesByYear exista um objeto para esse ano
            setEditedTaxesByYear((prev) => ({
                ...prev,
                [year]: prev[year] || {},
            }));

            // Salva também as parcelas calculadas
            setParcelValuesByYear((prev) => ({
                ...prev,
                [year]: initialParcel,
            }));

        } catch (error) {
            console.error(error);
            messageError("Erro ao buscar impostos por ano.");
        } finally {
            setLocalLoading(false);
        }
    };

    /**
     * Ao clicar em uma aba (ano)
     * Se houver alterações pendentes no ano atual que não foram salvas,
     * desfaz as alterações ao mudar de aba (conforme solicitado).
     */
    const handleSelectYearTab = async (selectedEventKey) => {
        if (selectedEventKey === 'add-new-year') {
            await handleAddYear();
            return;
        }

        // Desfaz alterações do ano que está sendo trocado
        setEditedTaxesByYear((prev) => ({
            ...prev,
            [activeYear]: {}
        }));

        setActiveYear(selectedEventKey);

        if (onYearChange) {
            onYearChange(selectedEventKey);
        }

        // Se não tiver carregado esse ano ainda, carrega
        if (!taxesByYear[selectedEventKey]) {
            await fetchTaxesByYear(selectedEventKey);
        }
    };

    /**
     * Cria nova aba (ano) copiando as referências do ano ativo
     */
    const handleAddYear = async () => {
        try {
            setLocalLoading(true);

            const sorted = [...availableYears].sort((a, b) => a - b);
            const maxYear = sorted[sorted.length - 1];
            const nextYear = maxYear >= activeYear ? maxYear + 1 : activeYear + 1;

            // Copia referências no back-end
            await copyYearReferences(activeYear, nextYear);

            // Adiciona localmente e ordena
            const updated = [...availableYears, nextYear].sort((a, b) => a - b);
            setAvailableYears(updated);

            // Carrega as taxas do novo ano
            await fetchTaxesByYear(nextYear);

            // Seleciona a nova aba
            setActiveYear(nextYear);
            if (onYearChange) {
                onYearChange(nextYear);
            }
        } catch (error) {
            console.error("Erro ao criar novo ano: ", error);
            messageError("Erro ao criar novo ano.");
        } finally {
            setLocalLoading(false);
        }
    };

    /**
     * Copia as refs do fromYear p/ o toYear
     */
    const copyYearReferences = async (fromYear, toYear) => {
        try {
            const refResponse = await taxesReferenceService.getByYear(fromYear);
            const fromRefs = refResponse.data || [];

            const promises = fromRefs.map(ref => {
                const dto = {
                    tax: ref.tax,
                    value: ref.value,
                    year: toYear
                };
                return taxesReferenceService.new(dto);
            });
            await Promise.all(promises);
        } catch (error) {
            throw error;
        }
    };

    /**
     * Scroll lateral
     */
    const handleScrollLeft = () => {
        if (tabsContainerRef.current) {
            tabsContainerRef.current.scrollLeft -= scrollAmount;
        }
    };
    const handleScrollRight = () => {
        if (tabsContainerRef.current) {
            tabsContainerRef.current.scrollLeft += scrollAmount;
        }
    };

    /**
     * Modal "Editar Ano" (opcional)
     */
    const handleEditYear = () => setShowModal(true);
    const handleClose = () => setShowModal(false);
    const handleSaveModal = async () => {
        setShowModal(false);
        // ...
    };

    /**
     * Pegar valor de um campo combinando taxesByYear e editedTaxesByYear
     */
    const getFieldValue = (year, taxId, field) => {
        const list = taxesByYear[year] || [];
        const item = list.find(t => t.id === taxId);
        if (!item) return '';
        if (
            editedTaxesByYear[year] &&
            editedTaxesByYear[year][taxId] &&
            editedTaxesByYear[year][taxId][field] !== undefined
        ) {
            return editedTaxesByYear[year][taxId][field];
        }
        return item[field];
    };

    /**
     * Setar valor num campo, atualizando editedTaxesByYear
     */
    const setFieldValue = (year, taxId, field, value) => {
        setEditedTaxesByYear((prev) => ({
            ...prev,
            [year]: {
                ...prev[year],
                [taxId]: {
                    ...prev[year]?.[taxId],
                    [field]: value
                }
            }
        }));

        if (onHandleChange) {
            onHandleChange(year, taxId, field, value);
        }
    };

    /**
     * Foco no campo Value => converte p/ raw (sem R$)
     */
    const handleValueFocus = (year, tax) => {
        setFocusedField(tax.id);
        const val = getFieldValue(year, tax.id, 'value');
        const formatted = formatCurrency(val);
        setOriginalValue((prev) => ({ ...prev, [tax.id]: formatted }));

        let raw = '';
        if (typeof val === 'number') {
            raw = val.toString().replace('.', ',');
        } else {
            raw = (val || '').replace(/[R$\s]/g, '');
        }
        setFieldValue(year, tax.id, 'value', raw);
    };

    /**
     * Blur => formata novamente e atualiza parcela
     */
    const handleValueBlur = (year, tax) => {
        setFocusedField(null);
        const val = getFieldValue(year, tax.id, 'value');
        if (!val) {
            const original = originalValue[tax.id] || '';
            setFieldValue(year, tax.id, 'value', original);
            return;
        }
        const formatted = formatCurrency(val);
        setFieldValue(year, tax.id, 'value', formatted);

        const numberVal = parseCurrency(val);
        const inst = parseInt(getFieldValue(year, tax.id, 'installments'), 10) || 1;
        const newParcel = inst > 0 ? formatCurrency(numberVal / inst) : '';

        setParcelValuesByYear((prev) => ({
            ...prev,
            [year]: {
                ...(prev[year] || {}),
                [tax.id]: newParcel
            }
        }));
    };

    /**
     * Ao mudar o Value digitado => recalcula parcela
     */
    const handleValueChange = (year, tax, e) => {
        const rawValue = e.target.value;
        setFieldValue(year, tax.id, 'value', rawValue);

        const valNum = parseCurrency(rawValue);
        const inst = parseInt(getFieldValue(year, tax.id, 'installments'), 10) || 1;
        if (inst > 0 && !isNaN(valNum)) {
            const newParcelValue = formatCurrency(valNum / inst);
            setParcelValuesByYear((prev) => ({
                ...prev,
                [year]: {
                    ...(prev[year] || {}),
                    [tax.id]: newParcelValue
                }
            }));
        } else {
            setParcelValuesByYear((prev) => ({
                ...prev,
                [year]: {
                    ...(prev[year] || {}),
                    [tax.id]: ''
                }
            }));
        }
    };

    /**
     * Ao mudar o número de parcelas => recalcula valor das parcelas
     */
    const handleInstallmentsChange = (year, tax, e) => {
        const newInst = parseInt(e.target.value, 10) || 1;
        setFieldValue(year, tax.id, 'installments', newInst);

        const val = parseCurrency(getFieldValue(year, tax.id, 'value'));
        if (newInst > 0 && !isNaN(val)) {
            const newParcel = formatCurrency(val / newInst);
            setParcelValuesByYear((prev) => ({
                ...prev,
                [year]: {
                    ...(prev[year] || {}),
                    [tax.id]: newParcel
                }
            }));
        } else {
            setParcelValuesByYear((prev) => ({
                ...prev,
                [year]: {
                    ...(prev[year] || {}),
                    [tax.id]: ''
                }
            }));
        }
    };

    /**
     * Ao editar diretamente o campo de Valor da Parcela
     */
    const handleParcelValueChange = (year, tax, e) => {
        const raw = e.target.value;
        setParcelValuesByYear((prev) => ({
            ...prev,
            [year]: {
                ...(prev[year] || {}),
                [tax.id]: raw
            }
        }));

        const parcelNumber = parseCurrency(raw);
        const inst = parseInt(getFieldValue(year, tax.id, 'installments'), 10) || 1;
        if (!isNaN(parcelNumber) && inst > 0) {
            const total = formatCurrency(parcelNumber * inst);
            setFieldValue(year, tax.id, 'value', total);
        } else {
            setFieldValue(year, tax.id, 'value', '');
        }
    };

    /**
     * Salvar apenas as alterações para o ano ativo,
     * criando novos registros em Taxes_reference
     */
    const handleSaveChanges = async () => {
        try {
            setLocalLoading(true);

            const changes = editedTaxesByYear[activeYear] || {};
            const promises = [];

            // Para cada taxId que foi alterada, cria um novo registro
            Object.keys(changes).forEach((taxId) => {
                const taxChanges = changes[taxId];
                if (taxChanges.value !== undefined && taxChanges.value !== null) {
                    const newRef = {
                        tax: { id: taxId },
                        value: parseFloat(parseCurrency(taxChanges.value)),
                        year: parseInt(activeYear, 10)
                    };
                    promises.push(taxesReferenceService.new(newRef));
                }
            });

            await Promise.all(promises);

            // Limpa as alterações do ano ativo
            setEditedTaxesByYear((prev) => ({
                ...prev,
                [activeYear]: {}
            }));

            messageSuccess("Alterações salvas com sucesso!");

            // Recarrega os dados do ano ativo para exibir os últimos valores
            await fetchTaxesByYear(activeYear);
        } catch (error) {
            console.error("Erro ao salvar alterações: ", error);
            messageError("Erro ao salvar alterações.");
        } finally {
            setLocalLoading(false);
        }
    };

    /**
     * Renderiza a tabela de cada ano
     */
    const renderYearTables = (year) => {
        const list = taxesByYear[year] || [];

        if (localLoading) {
            return (
                <div style={{ textAlign: 'center', margin: '20px' }}>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Carregando...</span>
                    </Spinner>
                </div>
            );
        }

        if (!list.length) {
            return <p>Nenhuma informação cadastrada para {year}.</p>;
        }

        // Agrupando por taxGroup
        const groups = [...new Set(list.map(t => t.taxGroup))];

        return groups.map(group => {
            const groupItems = list.filter(t => t.taxGroup === group);

            return (
                <div key={group} style={{ marginBottom: '2rem' }}>
                    <h5>{group}</h5>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Descrição</th>
                                <th>Valor Total</th>
                                <th>Valor das Parcelas</th>
                                <th>Parcelas</th>
                                <th>Dia do Vencimento</th>
                                <th>Mês de Início da Cobrança</th>
                                <th style={{ textAlign: 'center' }}>Ativo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {groupItems.map(taxItem => {
                                const val = getFieldValue(year, taxItem.id, 'value');
                                const displayVal = (focusedField === taxItem.id)
                                    ? val
                                    : (formatCurrency(val) || '');

                                const displayParcel = parcelValuesByYear[year]?.[taxItem.id] || '';

                                return (
                                    <tr key={taxItem.id}>
                                        <td>
                                            <Form.Control
                                                as="textarea"
                                                rows={1}
                                                style={{ resize: 'vertical' }}
                                                value={getFieldValue(year, taxItem.id, 'description') || ''}
                                                onChange={(e) =>
                                                    setFieldValue(year, taxItem.id, 'description', e.target.value)
                                                }
                                                size="sm"
                                            />
                                        </td>
                                        <td style={{ width: '10vw' }}>
                                            <Form.Control
                                                type="text"
                                                value={displayVal}
                                                onFocus={() => handleValueFocus(year, taxItem)}
                                                onBlur={() => handleValueBlur(year, taxItem)}
                                                onChange={(e) => handleValueChange(year, taxItem, e)}
                                                size="sm"
                                            />
                                        </td>
                                        <td style={{ width: '12vw' }}>
                                            <Form.Control
                                                type="text"
                                                value={displayParcel}
                                                onChange={(e) => handleParcelValueChange(year, taxItem, e)}
                                                size="sm"
                                            />
                                        </td>
                                        <td style={{ width: '80px' }}>
                                            <Form.Control
                                                type="number"
                                                value={getFieldValue(year, taxItem.id, 'installments') || ''}
                                                onChange={(e) => handleInstallmentsChange(year, taxItem, e)}
                                                size="sm"
                                            />
                                        </td>
                                        <td>
                                            <Form.Select
                                                value={getFieldValue(year, taxItem.id, 'dayExpire') || ''}
                                                onChange={(e) =>
                                                    setFieldValue(year, taxItem.id, 'dayExpire', e.target.value)
                                                }
                                                size="sm"
                                            >
                                                <option value="" disabled>Selecione um dia</option>
                                                {Array.from({ length: 28 }, (_, i) => i + 1).map(day => (
                                                    <option key={day} value={day}>
                                                        {day}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </td>
                                        <td>
                                            <Form.Select
                                                value={getFieldValue(year, taxItem.id, 'startMonth') || ''}
                                                onChange={(e) =>
                                                    setFieldValue(year, taxItem.id, 'startMonth', parseInt(e.target.value, 10))
                                                }
                                                size="sm"
                                            >
                                                <option value="">Selecione um mês</option>
                                                {monthOptions.map(m => (
                                                    <option key={m.value} value={m.value}>{m.label}</option>
                                                ))}
                                            </Form.Select>
                                        </td>
                                        <td className="text-center">
                                            <Form.Check
                                                type="checkbox"
                                                checked={
                                                    getFieldValue(year, taxItem.id, 'active') === 'S' ||
                                                    getFieldValue(year, taxItem.id, 'active') === true
                                                }
                                                onChange={(e) =>
                                                    setFieldValue(year, taxItem.id, 'active', e.target.checked)
                                                }
                                                style={{ transform: 'scale(1.2)' }}
                                            />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </div>
            );
        });
    };

    /**
     * CSS para scroll lateral e centralização das abas
     */
    const containerStyle = {
        position: 'relative',
        marginBottom: '1rem',
        display: 'flex',
        justifyContent: 'center'
    };
    const arrowStyle = {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        background: '#f8f9fa',
        border: '1px solid #ccc',
        borderRadius: '50%',
        width: '30px',
        height: '30px',
        cursor: 'pointer',
        zIndex: 9999,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    };
    const leftArrowStyle = {
        ...arrowStyle,
        left: 0
    };
    const rightArrowStyle = {
        ...arrowStyle,
        right: 0
    };
    const tabsWrapperStyle = {
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        margin: '0 40px',
        display: 'inline-block'
    };
    const centerTabsStyle = {
        display: 'flex',
        justifyContent: 'center'
    };

    return (
        <>
            {/* Overlay global de loading local */}
            {localLoading && (
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: "rgba(255,255,255,0.7)",
                        zIndex: 9999,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Carregando...</span>
                    </Spinner>
                </div>
            )}

            <div style={containerStyle}>
                <div style={leftArrowStyle} onClick={handleScrollLeft}>
                    {'<'}
                </div>

                <div style={tabsWrapperStyle} ref={tabsContainerRef}>
                    <Tabs
                        id="taxes-by-year"
                        activeKey={activeYear}
                        onSelect={handleSelectYearTab}
                        className="mb-3"
                        style={centerTabsStyle}
                    >
                        {availableYears.map((year) => (
                            <Tab eventKey={year} title={String(year)} key={year}>
                                {renderYearTables(year)}
                            </Tab>
                        ))}
                        <Tab eventKey="add-new-year" title="+" />
                    </Tabs>
                </div>

                <div style={rightArrowStyle} onClick={handleScrollRight}>
                    {'>'}
                </div>
            </div>

            {/* Botão de Salvar Alterações (salva apenas o ano ativo) */}
            <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
                <Button
                    label="Salvar Alterações"
                    icon="pi pi-save"
                    className="p-button-success"
                    onClick={handleSaveChanges}
                    disabled={Object.keys(editedTaxesByYear[activeYear] || {}).length === 0}
                />
            </div>

            {/* Modal para editar o "ano" (caso seja necessário) */}
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar ano de referência</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formTitle">
                            <Form.Label>Ano de referência</Form.Label>
                            <Form.Control
                                type="text"
                                value={yearTaxes}
                                onChange={(e) => setYearTaxes(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                        label="Fechar"
                        icon="pi pi-times"
                        className="p-button-text"
                    />
                    <Button
                        variant="primary"
                        onClick={handleSaveModal}
                        label="Salvar"
                        icon="pi pi-check"
                    />
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Taxes;
