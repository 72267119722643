import React, { useState, useEffect, useMemo, useContext } from "react";
import { messageError } from "../../components/toastr";
import Card from "../../components/card";
import Container from "../../components/container";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs, Spinner } from "react-bootstrap";
import { AuthContext } from "../../security/AuthContext";
import RolePermissionService from "../../app/service/rolePermissionService.js";
import TaxesService from "../../app/service/taxesService.js";
import MemberService from "../../app/service/memberService.js";
import * as Constant from '../../config/constants';

import Taxes from "./taxes";
import TaxPayments from "./tax_payments.js";
import PaymentRegister from "./payment_register.js";
import ReportPayments from "./reportPayments.js";

const Treasury = () => {
    const { user, isAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();
    const rolePermissionService = useMemo(() => new RolePermissionService(), []);

    const taxesService = useMemo(() => new TaxesService(), []);
    const memberService = useMemo(() => new MemberService(), []);

    // Aba principal
    const [activeTab, setActiveTab] = useState("payments");

    // Lista geral de taxas, usada também na aba "Recebimentos"
    const [taxes, setTaxes] = useState([]);

    // Lista geral de membros, usada em Recebimentos e outros lugares
    const [members, setMembers] = useState([]);

    // Loading global para a tela
    const [loading, setLoading] = useState(false);

    // Se o usuário pode ver todas as abas
    const [viewAllTabs, setViewAllTabs] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!isAuthenticated) {
                    messageError("Acesso negado. Procure um administrador.");
                    navigate("/login");
                    return;
                }

                // Se não for admin, checa permissão
                if (user && user.role !== 1) {
                    const responseMember = await memberService.getById(user.member);
                    const responseViewAllTabs = await rolePermissionService.hasPermission(
                        responseMember.data.lodgeRole,
                        Constant.VIEW_ALL_PAYMENTS
                    );
                    setViewAllTabs(responseViewAllTabs.data);

                } else {
                    setViewAllTabs(true);
                }

                setLoading(true);

                // Carrega taxes
                const respTaxes = await taxesService.getAll();
                const allTaxes = respTaxes.data || [];
                allTaxes.sort((a, b) => a.id - b.id);
                setTaxes(allTaxes);

                // Carrega members
                const respMembers = await memberService.getAllActive();
                const sortedMembers = (respMembers.data || []).sort((a, b) =>
                    a.name.localeCompare(b.name)
                );
                setMembers(sortedMembers);
            } catch (error) {
                console.error("Erro ao carregar dados: ", error);
                messageError("Erro ao carregar dados.");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [isAuthenticated, user, navigate, rolePermissionService, taxesService, memberService]);

    return (
        <Container>
            {/* Overlay global de loading */}
            {loading && (
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: "rgba(255,255,255,0.7)",
                        zIndex: 9999,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Carregando...</span>
                    </Spinner>
                </div>
            )}

            <Card
                title="Tesouraria"
                style={{ width: "100%", pointerEvents: loading ? "none" : "auto" }}
            >
                <Tabs
                    activeKey={activeTab}
                    onSelect={(k) => setActiveTab(k)}
                    id="treasury-tabs"
                    className="mb-3"
                >
                    {/* Essa aba sempre fica visível */}
                    <Tab
                        eventKey="payments"
                        title={<span style={{ color: "blue" }}>Recebimentos</span>}
                    >
                        <div style={{ paddingTop: "2vh", width: "100%", overflowX: "auto" }}>
                            <TaxPayments taxes={taxes} members={members} />
                        </div>
                    </Tab>

                    {/* Se viewAllTabs=true, mostra as outras abas */}
                    {viewAllTabs && (

                        <Tab
                            eventKey="taxes"
                            title={<span style={{ color: "blue" }}>Taxas</span>}
                        >
                            {taxes.length > 0 ? (
                                <Taxes taxes={taxes} />
                            ) : (
                                <p>Carregando taxas...</p>
                            )}
                        </Tab>

                    )}
                    {viewAllTabs && (
                        <Tab
                            eventKey="includingTaxes"
                            title={<span style={{ color: "blue" }}>Lançamentos</span>}
                        >
                            <div style={{ paddingTop: "2vh" }}>
                                <PaymentRegister taxes={taxes} members={members} />
                            </div>
                        </Tab>
                    )}
                    {viewAllTabs && (
                        <Tab
                            eventKey="hospitality"
                            title={<span style={{ color: "blue" }}>Hospitalaria</span>}
                        >
                            <div style={{ paddingTop: "2vh" }}>
                                <p>Em construção...</p>
                            </div>
                        </Tab>
                    )}

                    {viewAllTabs && (
                        <Tab
                            eventKey="reportPayments"
                            title={<span style={{ color: "blue" }}>Relatórios da Tesouraria</span>}
                        >
                            <div style={{ paddingTop: "2vh" }}>
                                <ReportPayments members={members} />
                            </div>
                        </Tab>
                    )}
                </Tabs>
            </Card>
        </Container>
    );
};

export default Treasury;
