import React, { useState, useEffect, useMemo } from "react";
import FormGroup from "../../components/form-group";
import SelectMenu from "../../components/selectMenu";
import TaxesPaymentsService from "../../app/service/taxesPaymentsService";
import { messageError } from "../../components/toastr";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { pdfHeader } from "../../components/pdfHeaderPdfMake.js";
import customVfs from "../../components/customVfs";
import { Table, Button, Tabs, Tab } from "react-bootstrap";

// Configurações PDFMake
pdfMake.vfs = {
    ...pdfFonts.pdfMake.vfs,
    ...customVfs,
};

pdfMake.fonts = {
    Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
        italics: "Roboto-Italic.ttf",
        bolditalics: "Roboto-MediumItalic.ttf",
    },
    GreatVibes: {
        normal: "GreatVibes-Regular.ttf",
        bold: "GreatVibes-Regular.ttf",
        italics: "GreatVibes-Regular.ttf",
        bolditalics: "GreatVibes-Regular.ttf",
    },
};

// Função de formatação para moedas em Real
const formatBRL = (value) =>
    Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
    }).format(value);

/**
 * Funções de agrupamento
 */
function groupByStatus(items) {
    const today = new Date();

    const pagos = items.filter((p) => p.dtPayment != null);
    const pendentes = items.filter((p) => {
        const expireDate = p.dtExpire ? new Date(p.dtExpire) : null;
        return p.dtPayment == null && expireDate && expireDate >= today;
    });
    const vencidos = items.filter((p) => {
        const expireDate = p.dtExpire ? new Date(p.dtExpire) : null;
        return p.dtPayment == null && expireDate && expireDate < today;
    });

    const result = [];
    if (pagos.length > 0) {
        result.push({ status: "Pago", items: pagos });
    }
    if (pendentes.length > 0) {
        result.push({ status: "Pendente", items: pendentes });
    }
    if (vencidos.length > 0) {
        result.push({ status: "Vencido", items: vencidos });
    }
    return result;
}

function groupByMemberAndReceipt(paymentsArray, members) {
    const mapMembers = new Map();
    for (const p of paymentsArray) {
        if (!mapMembers.has(p.member)) {
            mapMembers.set(p.member, []);
        }
        mapMembers.get(p.member).push(p);
    }

    const memberGroups = [];
    for (const [memberId, pays] of mapMembers.entries()) {
        memberGroups.push({ memberId, payments: pays });
    }

    memberGroups.sort((a, b) => {
        const nameA = (members.find((m) => m.id === a.memberId) || {}).name || "";
        const nameB = (members.find((m) => m.id === b.memberId) || {}).name || "";
        return nameA.localeCompare(nameB);
    });

    const result = [];
    for (const mg of memberGroups) {
        const receiptMap = new Map();
        const noReceiptList = [];

        mg.payments.forEach((p) => {
            const r = p.receipt;
            if (!r || r === "" || r === 0) {
                noReceiptList.push(p);
            } else {
                if (!receiptMap.has(r)) {
                    receiptMap.set(r, []);
                }
                receiptMap.get(r).push(p);
            }
        });

        let subGroups = [];

        for (const [receiptVal, arr] of receiptMap.entries()) {
            const splittedByStatus = groupByStatus(arr);
            splittedByStatus.forEach((groupStatus) => {
                subGroups.push({
                    receipt: receiptVal,
                    items: groupStatus.items,
                    status: groupStatus.status,
                });
            });
        }

        const splittedNoReceipt = groupByStatus(noReceiptList);
        splittedNoReceipt.forEach((groupStatus) => {
            subGroups.push({
                receipt: "",
                items: groupStatus.items,
                status: groupStatus.status,
            });
        });

        function statusOrder(s) {
            if (s === "Pago") return 1;
            if (s === "Pendente") return 2;
            if (s === "Vencido") return 3;
            return 4;
        }
        subGroups.sort((a, b) => statusOrder(a.status) - statusOrder(b.status));

        result.push({
            memberId: mg.memberId,
            subGroups,
        });
    }

    return result;
}

/**
 * Função de geração de PDF para a aba "Por Irmãos"
 */
function generatePdfReport({
    payments,
    members,
    selectedMember,
    selectedStatus,
    totalYearAll,
    totalYearPaid,
    totalYearOpen,
    totalYearVencido, // Novo parâmetro
}) {
    const { headerContent, headerStyles } = pdfHeader("");

    const tableHeader = [
        { text: "Membro", bold: true, fillColor: "#eeeeee" },
        { text: "Descrição", bold: true, fillColor: "#eeeeee" },
        { text: "Vencimento", bold: true, fillColor: "#eeeeee" },
        { text: "Recibo", bold: true, fillColor: "#eeeeee" },
        { text: "Pagamento", bold: true, fillColor: "#eeeeee" },
        { text: "Valor", bold: true, fillColor: "#eeeeee", alignment: "right" },
        { text: "Total", bold: true, fillColor: "#eeeeee", alignment: "right" },
    ];
    const tableBody = [tableHeader];

    const grouped = groupByMemberAndReceipt(payments, members);

    for (const g of grouped) {
        const memberObj = members.find((m) => m.id === g.memberId);
        const memberName = memberObj ? memberObj.name : "N/D";

        let totalLines = 0;
        g.subGroups.forEach((sg) => {
            totalLines += sg.items.length;
        });

        let currentLine = 0;

        for (const sg of g.subGroups) {
            const linesInThisReceipt = sg.items.length;

            let commonDtPaymentStr = "";
            if (sg.items[0].dtPayment) {
                const dtP = new Date(sg.items[0].dtPayment);
                commonDtPaymentStr = dtP.toLocaleDateString("pt-BR");
            }

            const subgroupTotal = sg.items.reduce(
                (acc, item) => acc + (item.amount || 0),
                0
            );

            let bgColorSubgroup = "#FFFFFF";
            const firstItem = sg.items[0];
            if (firstItem.dtPayment) {
                bgColorSubgroup = "#CEFFBA";
            } else if (
                firstItem.dtExpire &&
                new Date(firstItem.dtExpire) < new Date()
            ) {
                bgColorSubgroup = "#FFD1D1";
            }

            sg.items.forEach((p, index) => {
                const description = p.description
                    ? p.tax.taxGroup + " - " + p.description
                    : p.tax.taxGroup + " - " + p.tax.description;

                const dtExpireStr = p.dtExpire
                    ? new Date(p.dtExpire).toLocaleDateString("pt-BR")
                    : "";

                const amount = p.amount ? formatBRL(p.amount) : "";

                let bgColor = "#FFFFFF";
                if (p.dtPayment) {
                    bgColor = "#CEFFBA";
                } else if (p.dtExpire && new Date(p.dtExpire) < new Date()) {
                    bgColor = "#FFD1D1";
                }

                const row = [];

                // **(1) Membro**
                if (currentLine === 0) {
                    row.push({
                        text: memberName,
                        rowSpan: totalLines,
                        bold: true,
                        alignment: "center",
                        fillColor: "#FFFFFF",
                    });
                } else {
                    row.push("");
                }

                // **(2) Descrição**
                row.push({ text: description, fillColor: bgColor });

                // **(3) Vencimento**
                row.push({ text: dtExpireStr, fillColor: bgColor });

                // **(4) Recibo**
                if (sg.receipt && sg.receipt !== "" && index === 0) {
                    row.push({
                        text: String(sg.receipt),
                        rowSpan: linesInThisReceipt,
                        fillColor: bgColor,
                        alignment: "center",
                    });
                } else if (sg.receipt && sg.receipt !== "" && index > 0) {
                    row.push("");
                } else {
                    row.push({ text: "", fillColor: bgColor });
                }

                // **(5) Pagamento**
                if (commonDtPaymentStr && index === 0) {
                    row.push({
                        text: commonDtPaymentStr,
                        rowSpan: linesInThisReceipt,
                        fillColor: bgColor,
                        alignment: "center",
                    });
                } else if (commonDtPaymentStr && index > 0) {
                    row.push("");
                } else {
                    const dtPaymentStr = p.dtPayment
                        ? new Date(p.dtPayment).toLocaleDateString("pt-BR")
                        : "";
                    row.push({
                        text: dtPaymentStr,
                        fillColor: bgColor,
                        alignment: "center",
                    });
                }

                // **(6) Valor**
                row.push({ text: amount, fillColor: bgColor, alignment: "right" });

                // **(7) Total**
                if (index === 0) {
                    row.push({
                        text: formatBRL(subgroupTotal),
                        rowSpan: linesInThisReceipt,
                        fillColor: bgColorSubgroup,
                        alignment: "right",
                        bold: true,
                    });
                } else {
                    row.push("");
                }

                tableBody.push(row);
                currentLine++;
            });
        }
    }

    // Cabeçalho do relatório
    const selectedMemberName =
        selectedMember === "0" || selectedMember === ""
            ? "Todos os Membros"
            : `Membro: ${members.find((m) => m.id === parseInt(selectedMember, 10))?.name ||
            "N/D"
            }`;
    let statusText = "Todos";
    if (selectedStatus === "Pagos") statusText = "Pagos";
    else if (selectedStatus === "Pendentes") statusText = "Pendentes";
    else if (selectedStatus === "Vencidos") statusText = "Vencidos";

    // DocumentDefinition usa os totais passados como parâmetros
    const documentDefinition = {
        content: [
            ...headerContent,
            { text: "Relatório de Pagamentos", style: "header" },
            { text: `${selectedMemberName} - ${statusText}`, style: "subheader" },
            {
                table: {
                    headerRows: 1,
                    widths: ["auto", "*", "auto", "auto", "auto", "auto", "auto"],
                    body: tableBody,
                },
            },
            // Adicionando a nova tabela de totais anuais no PDF
            {
                table: {
                    widths: ["*", "auto"],
                    body: [
                        [
                            { text: "Descrição", style: "tableHeader" },
                            { text: "Valor", style: "tableHeader", alignment: "right" },
                        ],
                        [
                            {
                                text: "Total Geral",
                                bold: true,
                                alignment: "left",
                            },
                            {
                                text: formatBRL(totalYearAll),
                                alignment: "right",
                                bold: true,
                            },
                        ],
                        [
                            {
                                text: "Total Recebido",
                                bold: true,
                                alignment: "left",
                            },
                            {
                                text: formatBRL(totalYearPaid),
                                alignment: "right",
                                bold: true,
                            },
                        ],
                        [
                            {
                                text: "Total em Aberto",
                                bold: true,
                                alignment: "left",
                            },
                            {
                                text: formatBRL(totalYearOpen),
                                alignment: "right",
                                bold: true,
                            },
                        ],
                        [
                            {
                                text: "Total Vencido",
                                bold: true,
                                alignment: "left",
                            },
                            {
                                text: formatBRL(totalYearVencido),
                                alignment: "right",
                                bold: true,
                            },
                        ],
                    ],
                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return null;
                    },
                },
                margin: [0, 20, 0, 0], // Espaçamento acima da tabela
            },
        ],
        styles: {
            ...headerStyles,
            header: {
                fontSize: 18,
                bold: true,
                alignment: "center",
                margin: [0, 0, 0, 10],
            },
            subheader: {
                fontSize: 14,
                alignment: "center",
                margin: [0, 0, 0, 10],
            },
            tableHeader: {
                bold: true,
                fillColor: "#eeeeee",
                alignment: "center",
            },
        },
        footer: (currentPage, pageCount) => ({
            text: `${currentPage}/${pageCount}`,
            alignment: "right",
            margin: [0, 0, 40, 0],
        }),
    };

    pdfMake.createPdf(documentDefinition).open();
}

/**
 * Função de geração de PDF para a aba "Prestação de Contas"
 */
function generatePdfReportPrestacaoContas({
    groupedPayments, // Agora recebe os dados agrupados
    members,
    taxGroups,
    paymentTypes,
    totalReceived,
    totalDeposit, // Novo parâmetro
    totalCash, // Novo parâmetro
}) {
    const { headerContent, headerStyles } = pdfHeader("");

    // Cabeçalho dinâmico com Recibo, Data do Pagamento, Membro, taxGroups e paymentTypes
    const tableHeader = [
        { text: "Recibo", bold: true, fillColor: "#eeeeee" },
        { text: "Data do Pagamento", bold: true, fillColor: "#eeeeee" },
        { text: "Membro", bold: true, fillColor: "#eeeeee" },
        // Adicionar uma coluna para cada taxGroup
        ...taxGroups.map((tg) => ({
            text: tg,
            bold: true,
            fillColor: "#eeeeee",
            alignment: "right",
        })),
        // Adicionar uma coluna para cada paymentType
        ...paymentTypes.map((pt) => ({
            text: pt,
            bold: true,
            fillColor: "#eeeeee",
            alignment: "right",
        })),
    ];

    const tableBody = [tableHeader];

    // Preencher as linhas da tabela com dados agrupados
    groupedPayments.forEach((group) => {
        const row = [
            { text: group.receipt, alignment: "center" },
            { text: group.paymentDate, alignment: "center" },
            { text: group.memberName, alignment: "center" },
        ];

        // Preencher as colunas de taxGroups
        taxGroups.forEach((tg) => {
            row.push({
                text: group.taxGroups[tg] ? formatBRL(group.taxGroups[tg]) : "",
                alignment: "right",
            });
        });

        // Preencher as colunas de paymentTypes
        paymentTypes.forEach((pt) => {
            row.push({
                text: group.paymentTypes[pt] ? formatBRL(group.paymentTypes[pt]) : "",
                alignment: "right",
            });
        });

        tableBody.push(row);
    });

    // Adicionar linha de totais
    const totalRow = [
        { text: "Total Geral", alignment: "center", bold: true },
        { text: "--", alignment: "center", bold: true },
        { text: "--", alignment: "center", bold: true },
        // Totais para cada taxGroup
        ...taxGroups.map((tg) => {
            const total = groupedPayments
                .filter((g) => g.taxGroups[tg])
                .reduce((acc, g) => acc + g.taxGroups[tg], 0);
            return {
                text: total > 0 ? formatBRL(total) : "",
                alignment: "right",
                bold: true,
            };
        }),
        // Totais para cada paymentType
        ...paymentTypes.map((pt) => {
            const total = groupedPayments
                .filter((g) => g.paymentTypes[pt])
                .reduce((acc, g) => acc + g.paymentTypes[pt], 0);
            return {
                text: total > 0 ? formatBRL(total) : "",
                alignment: "right",
                bold: true,
            };
        }),
    ];

    tableBody.push(totalRow);

    const documentDefinition = {
        content: [
            ...headerContent,
            { text: "Prestação de Contas", style: "header" },
            {
                table: {
                    headerRows: 1,
                    widths: [
                        "auto", // Recibo
                        "auto", // Data do Pagamento
                        "*",    // Membro
                        ...taxGroups.map(() => "auto"),      // taxGroups
                        ...paymentTypes.map(() => "auto"),   // paymentTypes
                    ],
                    body: tableBody,
                },
            },
            // Adicionar tabela de totais gerais (já incluído na última linha)
            {
                table: {
                    widths: ["*", "auto"],
                    body: [
                        [
                            { text: "Descrição", style: "tableHeader" },
                            { text: "Valor", style: "tableHeader", alignment: "right" },
                        ],
                        [
                            {
                                text: "Total Geral",
                                bold: true,
                                alignment: "left",
                            },
                            {
                                text: formatBRL(totalReceived),
                                alignment: "right",
                                bold: true,
                            },
                        ],
                        [
                            {
                                text: "Total Geral em Depósito",
                                bold: true,
                                alignment: "left",
                            },
                            {
                                text: formatBRL(totalDeposit),
                                alignment: "right",
                                bold: true,
                            },
                        ],
                        [
                            {
                                text: "Total Geral em Espécie",
                                bold: true,
                                alignment: "left",
                            },
                            {
                                text: formatBRL(totalCash),
                                alignment: "right",
                                bold: true,
                            },
                        ],
                    ],
                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return null;
                    },
                },
                margin: [0, 20, 0, 0], // Espaçamento acima da tabela
            },
        ],
        styles: {
            ...headerStyles,
            header: {
                fontSize: 18,
                bold: true,
                alignment: "center",
                margin: [0, 0, 0, 10],
            },
            subheader: {
                fontSize: 14,
                alignment: "center",
                margin: [0, 0, 0, 10],
            },
            tableHeader: {
                bold: true,
                fillColor: "#eeeeee",
                alignment: "center",
            },
        },
        footer: (currentPage, pageCount) => ({
            text: `${currentPage}/${pageCount}`,
            alignment: "right",
            margin: [0, 0, 40, 0],
        }),
    };

    pdfMake.createPdf(documentDefinition).open();
}

const ReportPayments = ({ members }) => {
    // Estado para armazenar todos os pagamentos carregados
    const [allPayments, setAllPayments] = useState([]);

    // Estados para a aba "Por Irmãos"
    const [selectedMemberPorIrmaos, setSelectedMemberPorIrmaos] = useState("");
    const [selectedStatusPorIrmaos, setSelectedStatusPorIrmaos] = useState("");

    // Estados para a aba "Anual"
    const [selectedMemberLoja, setSelectedMemberLoja] = useState("");
    const [selectedStatusLoja, setSelectedStatusLoja] = useState("");

    // Estados para a aba "Prestação de Contas"
    const [taxGroups, setTaxGroups] = useState([]);
    const [paymentTypes, setPaymentTypes] = useState([]); // Novo estado para paymentTypes

    const taxesPaymentsService = useMemo(() => new TaxesPaymentsService(), []);

    // Carrega todos os pagamentos ao montar o componente
    useEffect(() => {
        loadAllPayments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Carregar taxGroups e paymentTypes a partir dos pagamentos carregados
    useEffect(() => {
        loadTaxGroups();
        loadPaymentTypes(); // Carregar paymentTypes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allPayments]);

    const loadAllPayments = async () => {
        try {
            const response = await taxesPaymentsService.getAll();
            setAllPayments(response.data || []);
        } catch (error) {
            console.error("Erro ao carregar pagamentos:", error);
            messageError("Erro ao carregar pagamentos.");
        }
    };

    const loadTaxGroups = () => {
        // Derivar taxGroups diretamente dos pagamentos carregados
        const groups = new Set();
        allPayments.forEach((p) => {
            if (p.tax && p.tax.taxGroup) {
                groups.add(p.tax.taxGroup);
            }
        });
        setTaxGroups(Array.from(groups));
    };

    const loadPaymentTypes = () => {
        // Derivar paymentTypes diretamente dos pagamentos carregados
        const types = new Set();
        allPayments.forEach((p) => {
            if (p.paymentType) {
                types.add(p.paymentType);
            }
        });
        setPaymentTypes(Array.from(types));
    };

    // Filtro para a aba "Por Irmãos"
    const paymentsPorIrmaos = useMemo(() => {
        if (!allPayments) return [];

        let data = [...allPayments];

        // Filtro por membro
        if (selectedMemberPorIrmaos !== "" && selectedMemberPorIrmaos !== "0") {
            data = data.filter((p) => p.member === parseInt(selectedMemberPorIrmaos, 10));
        }

        // Filtro por status
        const today = new Date();
        if (selectedStatusPorIrmaos === "Pagos") {
            data = data.filter((p) => p.dtPayment != null);
        } else if (selectedStatusPorIrmaos === "Pendentes") {
            data = data.filter((p) => {
                const expireDate = p.dtExpire ? new Date(p.dtExpire) : null;
                return p.dtPayment == null && expireDate && expireDate >= today;
            });
        } else if (selectedStatusPorIrmaos === "Vencidos") {
            data = data.filter((p) => {
                const expireDate = p.dtExpire ? new Date(p.dtExpire) : null;
                return p.dtPayment == null && expireDate && expireDate < today;
            });
        }
        return data;
    }, [allPayments, selectedMemberPorIrmaos, selectedStatusPorIrmaos]);

    // Filtro para a aba "Anual"
    const paymentsLoja = useMemo(() => {
        if (!allPayments) return [];

        let data = [...allPayments];

        // Filtro por membro na Loja
        if (selectedMemberLoja !== "" && selectedMemberLoja !== "0") {
            data = data.filter((p) => p.member === parseInt(selectedMemberLoja, 10));
        }

        // Filtro por status na Loja
        const today = new Date();
        if (selectedStatusLoja === "Pagos") {
            data = data.filter((p) => p.dtPayment != null);
        } else if (selectedStatusLoja === "Pendentes") {
            data = data.filter((p) => {
                const expireDate = p.dtExpire ? new Date(p.dtExpire) : null;
                return p.dtPayment == null && expireDate && expireDate >= today;
            });
        } else if (selectedStatusLoja === "Vencidos") {
            data = data.filter((p) => {
                const expireDate = p.dtExpire ? new Date(p.dtExpire) : null;
                return p.dtPayment == null && expireDate && expireDate < today;
            });
        }
        return data;
    }, [allPayments, selectedMemberLoja, selectedStatusLoja]);

    // Filtro para a aba "Prestação de Contas"
    const paymentsPrestacaoContas = useMemo(() => {
        if (!allPayments) return [];

        // Filtrar apenas os pagamentos recebidos (dtPayment não nulo)
        let data = allPayments.filter((p) => p.dtPayment != null);
        data.sort((a, b) => {
            // Tratar casos onde o recibo pode ser vazio ou nulo
            if (a.receipt === "" || a.receipt === null) return 1;
            if (b.receipt === "" || b.receipt === null) return -1;

            // Comparar numericamente se os recibos são números
            // Se os recibos forem strings, use localeCompare
            if (typeof a.receipt === "number" && typeof b.receipt === "number") {
                return a.receipt - b.receipt;
            } else {
                return String(a.receipt).localeCompare(String(b.receipt));
            }
        });
        return data;
    }, [allPayments]);

    // Cálculos para Resumo Anual utilizando useMemo para "Por Irmãos"
    const [monthlyTotalsPorIrmaos, monthlyTotalsPaidPorIrmaos, monthlyTotalsOpenPorIrmaos, monthlyTotalsVencidosPorIrmaos] = useMemo(() => {
        const totals = Array(12).fill(0);
        const totalsPaid = Array(12).fill(0);
        const totalsOpen = Array(12).fill(0);
        const totalsVencidos = Array(12).fill(0);

        paymentsPorIrmaos.forEach((p) => {
            if (!p.dtExpire || !p.amount) return;
            const m = new Date(p.dtExpire).getMonth();
            totals[m] += p.amount;
            if (p.dtPayment) {
                totalsPaid[m] += p.amount;
            } else {
                totalsOpen[m] += p.amount;
                const today = new Date();
                if (new Date(p.dtExpire) < today) {
                    totalsVencidos[m] += p.amount;
                }
            }
        });

        return [totals, totalsPaid, totalsOpen, totalsVencidos];
    }, [paymentsPorIrmaos]);

    const sumArray = (arr) => arr.reduce((acc, val) => acc + val, 0);

    const totalYearAllPorIrmaos = sumArray(monthlyTotalsPorIrmaos);
    const totalYearPaidPorIrmaos = sumArray(monthlyTotalsPaidPorIrmaos);
    const totalYearOpenPorIrmaos = sumArray(monthlyTotalsOpenPorIrmaos);
    const totalYearVencidoPorIrmaos = sumArray(monthlyTotalsVencidosPorIrmaos);

    // Cálculos para Resumo Anual utilizando useMemo para "Anual"
    const [monthlyTotalsLoja, monthlyTotalsPaidLoja, monthlyTotalsOpenLoja, monthlyTotalsVencidosLoja] = useMemo(() => {
        const totals = Array(12).fill(0);
        const totalsPaid = Array(12).fill(0);
        const totalsOpen = Array(12).fill(0);
        const totalsVencidos = Array(12).fill(0);

        paymentsLoja.forEach((p) => {
            if (!p.dtExpire || !p.amount) return;
            const m = new Date(p.dtExpire).getMonth();
            totals[m] += p.amount;
            if (p.dtPayment) {
                totalsPaid[m] += p.amount;
            } else {
                totalsOpen[m] += p.amount;
                const today = new Date();
                if (new Date(p.dtExpire) < today) {
                    totalsVencidos[m] += p.amount;
                }
            }
        });

        return [totals, totalsPaid, totalsOpen, totalsVencidos];
    }, [paymentsLoja]);

    const totalYearAllLoja = sumArray(monthlyTotalsLoja);
    const totalYearPaidLoja = sumArray(monthlyTotalsPaidLoja);
    const totalYearOpenLoja = sumArray(monthlyTotalsOpenLoja);
    const totalYearVencidoLoja = sumArray(monthlyTotalsVencidosLoja);

    // Cálculos para "Prestação de Contas"
    const groupedPaymentsPrestacaoContas = useMemo(() => {
        const groups = {};
        paymentsPrestacaoContas.forEach((p) => {
            const receipt = p.receipt || 'N/D'; // 'N/D' para recibos inexistentes
            if (!groups[receipt]) {
                const member = members.find(m => m.id === p.member);
                groups[receipt] = {
                    receipt,
                    paymentDate: p.dtPayment ? new Date(p.dtPayment).toLocaleDateString("pt-BR") : "N/D",
                    memberName: member ? member.name : "N/D",
                    taxGroups: {},
                    paymentTypes: {},
                    totalAmount: 0,
                };
            }
            const group = groups[receipt];

            // Soma por taxGroup
            if (p.tax && p.tax.taxGroup) {
                group.taxGroups[p.tax.taxGroup] = (group.taxGroups[p.tax.taxGroup] || 0) + (p.amount || 0);
            }

            // Soma por paymentType
            if (p.paymentType) {
                group.paymentTypes[p.paymentType] = (group.paymentTypes[p.paymentType] || 0) + (p.amount || 0);
            }

            // Soma total do grupo
            group.totalAmount += p.amount || 0;
        });
        return Object.values(groups);
    }, [paymentsPrestacaoContas, members]);

    const totalReceivedPrestacaoContas = useMemo(() => {
        return paymentsPrestacaoContas.reduce((acc, p) => acc + (p.amount || 0), 0);
    }, [paymentsPrestacaoContas]);

    // Cálculos adicionais para Depósito e Espécie em "Prestação de Contas"
    const totalDepositPrestacaoContas = useMemo(() => {
        return groupedPaymentsPrestacaoContas
            .filter(g => g.paymentTypes["Depósito"])
            .reduce((acc, g) => acc + g.paymentTypes["Depósito"], 0);
    }, [groupedPaymentsPrestacaoContas]);

    const totalCashPrestacaoContas = useMemo(() => {
        return groupedPaymentsPrestacaoContas
            .filter(g => g.paymentTypes["Em espécie"])
            .reduce((acc, g) => acc + g.paymentTypes["Em espécie"], 0);
    }, [groupedPaymentsPrestacaoContas]);

    // Obter todos os taxGroups e paymentTypes disponíveis
    const availableTaxGroups = useMemo(() => {
        const groups = new Set();
        allPayments.forEach((p) => {
            if (p.tax && p.tax.taxGroup) {
                groups.add(p.tax.taxGroup);
            }
        });
        return Array.from(groups);
    }, [allPayments]);

    const availablePaymentTypes = useMemo(() => {
        const types = new Set();
        allPayments.forEach((p) => {
            if (p.paymentType) {
                types.add(p.paymentType);
            }
        });
        return Array.from(types);
    }, [allPayments]);

    // Função de geração de PDF para a aba "Por Irmãos"
    const handleGeneratePdfPorIrmaos = () => {
        generatePdfReport({
            payments: paymentsPorIrmaos,
            members,
            selectedMember: selectedMemberPorIrmaos,
            selectedStatus: selectedStatusPorIrmaos,
            totalYearAll: totalYearAllPorIrmaos,
            totalYearPaid: totalYearPaidPorIrmaos,
            totalYearOpen: totalYearOpenPorIrmaos,
            totalYearVencido: totalYearVencidoPorIrmaos, // Passando o novo total
        });
    };

    // Função de geração de PDF para a aba "Prestação de Contas"
    const handleGeneratePdfPrestacaoContas = () => {
        generatePdfReportPrestacaoContas({
            groupedPayments: groupedPaymentsPrestacaoContas, // Passa os dados agrupados
            members,
            taxGroups: availableTaxGroups,
            paymentTypes: availablePaymentTypes, // Passar paymentTypes
            totalReceived: totalReceivedPrestacaoContas,
            totalDeposit: totalDepositPrestacaoContas,
            totalCash: totalCashPrestacaoContas,
        });
    };

    // Função auxiliar para evitar funções dentro de loops (fixa os avisos no ESLint)
    const renderRows = (grouped) => {
        const rows = [];

        for (const g of grouped) {
            const memberObj = members.find((m) => m.id === g.memberId);
            const memberName = memberObj ? memberObj.name : "N/D";

            let totalLines = 0;
            g.subGroups.forEach((sg) => {
                totalLines += sg.items.length;
            });

            let currentLine = 0;

            for (const sg of g.subGroups) {
                const linesInThisReceipt = sg.items.length;

                let commonDtPaymentStr = "";
                if (sg.items[0].dtPayment) {
                    const dtP = new Date(sg.items[0].dtPayment);
                    commonDtPaymentStr = dtP.toLocaleDateString("pt-BR");
                }

                const subgroupTotal = sg.items.reduce(
                    (acc, item) => acc + (item.amount || 0),
                    0
                );

                let bgColorSubgroup = "#FFFFFF";
                const firstItem = sg.items[0];
                if (firstItem.dtPayment) {
                    bgColorSubgroup = "#CEFFBA";
                } else if (
                    firstItem.dtExpire &&
                    new Date(firstItem.dtExpire) < new Date()
                ) {
                    bgColorSubgroup = "#FFD1D1";
                }

                sg.items.forEach((p, index) => {
                    const description = p.description
                        ? p.tax.taxGroup + " - " + p.description
                        : p.tax.taxGroup + " - " + p.tax.description;

                    const dtExpireStr = p.dtExpire
                        ? new Date(p.dtExpire).toLocaleDateString("pt-BR")
                        : "";

                    const amount = p.amount ? formatBRL(p.amount) : "";

                    let bgColor = "#FFFFFF";
                    if (p.dtPayment) {
                        bgColor = "#CEFFBA";
                    } else if (p.dtExpire && new Date(p.dtExpire) < new Date()) {
                        bgColor = "#FFD1D1";
                    }

                    const row = [];

                    // **(1) Membro**
                    if (currentLine === 0) {
                        row.push({
                            text: memberName,
                            rowSpan: totalLines,
                            bold: true,
                            alignment: "center",
                            fillColor: "#FFFFFF",
                        });
                    } else {
                        row.push("");
                    }

                    // **(2) Descrição**
                    row.push({ text: description, fillColor: bgColor });

                    // **(3) Vencimento**
                    row.push({ text: dtExpireStr, fillColor: bgColor });

                    // **(4) Recibo**
                    if (sg.receipt && sg.receipt !== "" && index === 0) {
                        row.push({
                            text: String(sg.receipt),
                            rowSpan: linesInThisReceipt,
                            fillColor: bgColor,
                            alignment: "center",
                        });
                    } else if (sg.receipt && sg.receipt !== "" && index > 0) {
                        row.push("");
                    } else {
                        row.push({ text: "", fillColor: bgColor });
                    }

                    // **(5) Pagamento**
                    if (commonDtPaymentStr && index === 0) {
                        row.push({
                            text: commonDtPaymentStr,
                            rowSpan: linesInThisReceipt,
                            fillColor: bgColor,
                            alignment: "center",
                        });
                    } else if (commonDtPaymentStr && index > 0) {
                        row.push("");
                    } else {
                        const dtPaymentStr = p.dtPayment
                            ? new Date(p.dtPayment).toLocaleDateString("pt-BR")
                            : "";
                        row.push({
                            text: dtPaymentStr,
                            fillColor: bgColor,
                            alignment: "center",
                        });
                    }

                    // **(6) Valor**
                    row.push({ text: amount, fillColor: bgColor, alignment: "right" });

                    // **(7) Total**
                    if (index === 0) {
                        row.push({
                            text: formatBRL(subgroupTotal),
                            rowSpan: linesInThisReceipt,
                            fillColor: bgColorSubgroup,
                            alignment: "right",
                            bold: true,
                        });
                    } else {
                        row.push("");
                    }

                    rows.push(row);
                    currentLine++;
                });
            }
        }

        return rows;
    };

    return (
        <div style={{ margin: "2rem" }}>
            {/* Abas */}
            <Tabs defaultActiveKey="porIrmaos" id="report-payments-tabs">
                {/* ABA "Por Irmãos" */}
                <Tab eventKey="porIrmaos" title="Por Irmãos">
                    <div
                        style={{
                            display: "flex",
                            gap: "20px",
                            alignItems: "flex-end",
                            marginBottom: "20px",
                        }}
                    >
                        <FormGroup label="Irmão:" htmlFor="selectMemberPorIrmaos">
                            <SelectMenu
                                className="form-control"
                                list={[
                                    { label: "Selecione", value: "" },
                                    { label: "Todos", value: "0" },
                                    ...members.map((member) => ({
                                        label: member.name,
                                        value: member.id,
                                    })),
                                ]}
                                value={selectedMemberPorIrmaos}
                                onChange={(e) => setSelectedMemberPorIrmaos(e.target.value)}
                            />
                        </FormGroup>

                        <FormGroup label="Situação:" htmlFor="selectStatusPorIrmaos">
                            <SelectMenu
                                className="form-control"
                                list={[
                                    { label: "Todos", value: "" },
                                    { label: "Pagos", value: "Pagos" },
                                    { label: "Pendentes", value: "Pendentes" },
                                    { label: "Vencidos", value: "Vencidos" },
                                ]}
                                value={selectedStatusPorIrmaos}
                                onChange={(e) => setSelectedStatusPorIrmaos(e.target.value)}
                            />
                        </FormGroup>
                    </div>

                    {paymentsPorIrmaos.length > 0 ? (
                        <>
                            {/* Tabela "Por Irmãos" */}
                            <Table
                                bordered
                                size="sm"
                                style={{
                                    marginTop: "1rem",
                                    border: "3px solid black",
                                    borderCollapse: "collapse",
                                }}
                            >
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: "center" }}>Membro</th>
                                        <th style={{ textAlign: "center" }}>Descrição</th>
                                        <th style={{ textAlign: "center" }}>Vencimento</th>
                                        <th style={{ textAlign: "center" }}>Recibo</th>
                                        <th style={{ textAlign: "center" }}>Pagamento</th>
                                        <th style={{ textAlign: "center" }}>Valor</th>
                                        <th style={{ textAlign: "center" }}>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(() => {
                                        // Usa a função groupByMemberAndReceipt
                                        const grouped = groupByMemberAndReceipt(paymentsPorIrmaos, members);
                                        const rows = renderRows(grouped); // Utiliza a função auxiliar

                                        return rows.map((row, index) => (
                                            <tr key={index}>
                                                {row.map((cell, cellIndex) => {
                                                    if (cell === "") {
                                                        return <td key={cellIndex} style={{ display: "none" }} />;
                                                    }

                                                    return (
                                                        <td
                                                            key={cellIndex}
                                                            rowSpan={cell.rowSpan || 1}
                                                            style={{
                                                                textAlign: cell.alignment || "left",
                                                                backgroundColor: cell.fillColor || "transparent",
                                                                fontWeight: cell.bold ? "bold" : "normal",
                                                            }}
                                                        >
                                                            {cell.text}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        ));
                                    })()}
                                </tbody>
                            </Table>

                            {/* Nova Tabela de Totais Anuais (Compacta) */}
                            <Table
                                bordered
                                size="sm"
                                style={{
                                    marginTop: "1rem",
                                    border: "2px solid black",
                                    borderCollapse: "collapse",
                                    width: "30%", // Define a largura da tabela para 30% do contêiner
                                }}
                            >
                                <tbody>
                                    {/* Total Geral */}
                                    <tr>
                                        <td
                                            style={{
                                                fontWeight: "bold",
                                                textAlign: "left",
                                                padding: "4px 8px",
                                            }}
                                        >
                                            Total Geral
                                        </td>
                                        <td
                                            style={{
                                                textAlign: "right",
                                                fontWeight: "bold",
                                                padding: "4px 8px",
                                            }}
                                        >
                                            {totalYearAllPorIrmaos > 0 ? formatBRL(totalYearAllPorIrmaos) : "R$ 0,00"}
                                        </td>
                                    </tr>

                                    {/* Total Recebido */}
                                    <tr>
                                        <td
                                            style={{
                                                fontWeight: "bold",
                                                textAlign: "left",
                                                padding: "4px 8px",
                                            }}
                                        >
                                            Total Recebido
                                        </td>
                                        <td
                                            style={{
                                                textAlign: "right",
                                                fontWeight: "bold",
                                                padding: "4px 8px",
                                            }}
                                        >
                                            {totalYearPaidPorIrmaos > 0 ? formatBRL(totalYearPaidPorIrmaos) : "R$ 0,00"}
                                        </td>
                                    </tr>

                                    {/* Total em Aberto */}
                                    <tr>
                                        <td
                                            style={{
                                                fontWeight: "bold",
                                                textAlign: "left",
                                                padding: "4px 8px",
                                            }}
                                        >
                                            Total em Aberto
                                        </td>
                                        <td
                                            style={{
                                                textAlign: "right",
                                                fontWeight: "bold",
                                                padding: "4px 8px",
                                            }}
                                        >
                                            {totalYearOpenPorIrmaos > 0 ? formatBRL(totalYearOpenPorIrmaos) : "R$ 0,00"}
                                        </td>
                                    </tr>

                                    {/* Total Vencido */}
                                    <tr>
                                        <td
                                            style={{
                                                fontWeight: "bold",
                                                textAlign: "left",
                                                padding: "4px 8px",
                                                color: "red", // Opcional: destaque em vermelho
                                            }}
                                        >
                                            Total Vencido
                                        </td>
                                        <td
                                            style={{
                                                textAlign: "right",
                                                fontWeight: "bold",
                                                padding: "4px 8px",
                                                color: "red", // Opcional: destaque em vermelho
                                            }}
                                        >
                                            {totalYearVencidoPorIrmaos > 0 ? formatBRL(totalYearVencidoPorIrmaos) : "R$ 0,00"}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                            {/* Fim da Nova Tabela de Totais Anuais */}

                            <Button
                                variant="info"
                                onClick={handleGeneratePdfPorIrmaos}
                                style={{ marginTop: "1rem" }}
                            >
                                Gerar PDF
                            </Button>
                        </>
                    ) : (
                        <p>Nenhum pagamento encontrado para os critérios selecionados.</p>
                    )}
                </Tab>

                {/* ABA "Anual" */}
                <Tab eventKey="anual" title="Anual">
                    <div
                        style={{
                            display: "flex",
                            gap: "20px",
                            alignItems: "flex-end",
                            marginBottom: "20px",
                        }}
                    >
                        {/* Dropdown para Loja: Membro */}
                        <FormGroup label="Irmão:" htmlFor="selectMemberLoja">
                            <SelectMenu
                                className="form-control"
                                list={[
                                    { label: "Selecione", value: "" },
                                    { label: "Todos", value: "0" },
                                    ...members.map((member) => ({
                                        label: member.name,
                                        value: member.id,
                                    })),
                                ]}
                                value={selectedMemberLoja}
                                onChange={(e) => setSelectedMemberLoja(e.target.value)}
                            />
                        </FormGroup>

                        {/* Dropdown para Loja: Situação */}
                        <FormGroup label="Situação:" htmlFor="selectStatusLoja">
                            <SelectMenu
                                className="form-control"
                                list={[
                                    { label: "Todos", value: "" },
                                    { label: "Pagos", value: "Pagos" },
                                    { label: "Pendentes", value: "Pendentes" },
                                    { label: "Vencidos", value: "Vencidos" },
                                ]}
                                value={selectedStatusLoja}
                                onChange={(e) => setSelectedStatusLoja(e.target.value)}
                            />
                        </FormGroup>
                    </div>

                    {paymentsLoja.length === 0 ? (
                        <p>Nenhum pagamento encontrado para os critérios selecionados.</p>
                    ) : (
                        <>
                            <Table
                                striped
                                bordered
                                hover
                                size="sm"
                                style={{ border: "3px solid black", borderCollapse: "collapse" }}
                            >
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: "center" }}> </th>
                                        {[...Array(12).keys()].map((i) => {
                                            const monthName = new Date(0, i)
                                                .toLocaleString("pt-BR", { month: "short" })
                                                .toUpperCase();
                                            return (
                                                <th key={i} style={{ textAlign: "center" }}>
                                                    {monthName}
                                                </th>
                                            );
                                        })}
                                        <th style={{ textAlign: "center" }}>TOTAL</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* Linha: TOTAL GERAL */}
                                    <tr>
                                        <td
                                            style={{
                                                fontWeight: "bold",
                                                textAlign: "center",
                                            }}
                                        >
                                            TOTAL GERAL
                                        </td>
                                        {monthlyTotalsLoja.map((val, i) => (
                                            <td
                                                key={i}
                                                style={{ textAlign: "right", fontWeight: "bold" }}
                                            >
                                                {val > 0 ? formatBRL(val) : ""}
                                            </td>
                                        ))}
                                        <td style={{ textAlign: "right", fontWeight: "bold" }}>
                                            {totalYearAllLoja > 0 ? formatBRL(totalYearAllLoja) : ""}
                                        </td>
                                    </tr>

                                    {/* Linha: TOTAL RECEBIDO */}
                                    <tr>
                                        <td
                                            style={{
                                                fontWeight: "bold",
                                                textAlign: "center",
                                                color: "green",
                                            }}
                                        >
                                            TOTAL RECEBIDO
                                        </td>
                                        {monthlyTotalsPaidLoja.map((val, i) => (
                                            <td
                                                key={`paid-${i}`}
                                                style={{
                                                    textAlign: "right",
                                                    fontWeight: "bold",
                                                    color: "green",
                                                }}
                                            >
                                                {val > 0 ? formatBRL(val) : ""}
                                            </td>
                                        ))}
                                        <td
                                            style={{
                                                textAlign: "right",
                                                fontWeight: "bold",
                                                color: "green",
                                            }}
                                        >
                                            {totalYearPaidLoja > 0 ? formatBRL(totalYearPaidLoja) : ""}
                                        </td>
                                    </tr>

                                    {/* Linha: TOTAL EM ABERTO */}
                                    <tr>
                                        <td
                                            style={{
                                                fontWeight: "bold",
                                                textAlign: "center",
                                            }}
                                        >
                                            TOTAL EM ABERTO
                                        </td>
                                        {monthlyTotalsOpenLoja.map((val, i) => (
                                            <td
                                                key={`open-${i}`}
                                                style={{
                                                    textAlign: "right",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {val > 0 ? formatBRL(val) : ""}
                                            </td>
                                        ))}
                                        <td style={{ textAlign: "right", fontWeight: "bold" }}>
                                            {totalYearOpenLoja > 0 ? formatBRL(totalYearOpenLoja) : ""}
                                        </td>
                                    </tr>

                                    {/* Linha: TOTAL VENCIDO */}
                                    <tr>
                                        <td
                                            style={{
                                                fontWeight: "bold",
                                                textAlign: "center",
                                                color: "red",
                                            }}
                                        >
                                            TOTAL VENCIDO
                                        </td>
                                        {monthlyTotalsVencidosLoja.map((val, i) => (
                                            <td
                                                key={`vencido-${i}`}
                                                style={{
                                                    textAlign: "right",
                                                    fontWeight: "bold",
                                                    color: "red",
                                                }}
                                            >
                                                {val > 0 ? formatBRL(val) : ""}
                                            </td>
                                        ))}
                                        <td
                                            style={{
                                                textAlign: "right",
                                                fontWeight: "bold",
                                                color: "red",
                                            }}
                                        >
                                            {totalYearVencidoLoja > 0 ? formatBRL(totalYearVencidoLoja) : ""}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>

                            {/* Botão de Geração de PDF (Opcional) */}
                            {/* Se desejar adicionar um botão para gerar PDF da aba "Anual", descomente o trecho abaixo */}
                            {/* 
                            <Button
                                variant="primary"
                                onClick={handleGeneratePdfAnual}
                                style={{ marginTop: "1rem" }}
                            >
                                Gerar PDF Anual
                            </Button>
                            */}
                        </>
                    )}
                </Tab>

                {/* ABA "Prestação de Contas" */}
                <Tab eventKey="prestacaoContas" title="Prestação de Contas">
                    <div
                        style={{
                            display: "flex",
                            gap: "20px",
                            alignItems: "flex-end",
                            marginBottom: "20px",
                        }}
                    >
                        {/* Dropdown para selecionar TaxGroup (opcional) */}
                        <FormGroup label="Fonte:" htmlFor="selectTaxGroupPrestacaoContas">
                            <SelectMenu
                                className="form-control"
                                list={[
                                    { label: "Todos", value: "" },
                                    ...taxGroups.map((group) => ({
                                        label: group,
                                        value: group,
                                    })),
                                ]}
                                value={""} // Valor fixo para mostrar todos
                                onChange={() => { }} // Sem ação, pois queremos todos
                            />
                        </FormGroup>
                    </div>

                    {groupedPaymentsPrestacaoContas.length === 0 ? (
                        <p>Nenhum pagamento recebido encontrado.</p>
                    ) : (
                        <>
                            {/* Tabela "Prestação de Contas" Agrupada */}
                            <Table
                                striped
                                bordered
                                hover
                                size="sm"
                                style={{ border: "3px solid black", borderCollapse: "collapse", width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: "center" }}>Recibo</th>
                                        <th style={{ textAlign: "center" }}>Data do Pagamento</th>
                                        <th style={{ textAlign: "center" }}>Irmão</th>
                                        {/* Colunas para cada taxGroup */}
                                        {availableTaxGroups.map((tg) => (
                                            <th key={`tax-${tg}`} style={{ textAlign: "center" }}>
                                                {tg}
                                            </th>
                                        ))}
                                        {/* Colunas para cada paymentType */}
                                        {availablePaymentTypes.map((pt) => (
                                            <th key={`pt-${pt}`} style={{ textAlign: "center" }}>
                                                {pt}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {groupedPaymentsPrestacaoContas.map((group, index) => (
                                        <tr key={index}>
                                            {/* Recibo */}
                                            <td style={{ textAlign: "center" }}>{group.receipt}</td>

                                            {/* Data do Pagamento */}
                                            <td style={{ textAlign: "center" }}>{group.paymentDate}</td>

                                            {/* Membro */}
                                            <td style={{ textAlign: "center" }}>{group.memberName}</td>

                                            {/* Colunas para cada taxGroup */}
                                            {availableTaxGroups.map((tg, idx) => (
                                                <td key={`tg-${idx}`} style={{ textAlign: "right" }}>
                                                    {group.taxGroups[tg] ? formatBRL(group.taxGroups[tg]) : ""}
                                                </td>
                                            ))}

                                            {/* Colunas para cada paymentType */}
                                            {availablePaymentTypes.map((pt, idx) => (
                                                <td key={`pt-${idx}`} style={{ textAlign: "right" }}>
                                                    {group.paymentTypes[pt] ? formatBRL(group.paymentTypes[pt]) : ""}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}

                                    {/* Linha de Total Geral */}
                                    <tr>
                                        <td style={{ textAlign: "center", fontWeight: "bold" }}>Total Geral</td>
                                        <td style={{ textAlign: "center", fontWeight: "bold" }}>--</td>
                                        <td style={{ textAlign: "center", fontWeight: "bold" }}>--</td>

                                        {/* Totais para cada taxGroup */}
                                        {availableTaxGroups.map((tg, idx) => {
                                            const total = groupedPaymentsPrestacaoContas
                                                .filter(g => g.taxGroups[tg])
                                                .reduce((acc, g) => acc + g.taxGroups[tg], 0);
                                            return (
                                                <td key={`total-tg-${idx}`} style={{ textAlign: "right", fontWeight: "bold" }}>
                                                    {total > 0 ? formatBRL(total) : ""}
                                                </td>
                                            );
                                        })}

                                        {/* Totais para cada paymentType */}
                                        {availablePaymentTypes.map((pt, idx) => {
                                            const total = groupedPaymentsPrestacaoContas
                                                .filter(g => g.paymentTypes[pt])
                                                .reduce((acc, g) => acc + g.paymentTypes[pt], 0);
                                            return (
                                                <td key={`total-pt-${idx}`} style={{ textAlign: "right", fontWeight: "bold" }}>
                                                    {total > 0 ? formatBRL(total) : ""}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                </tbody>
                            </Table>

                            {/* Tabela de Totais Gerais */}
                            <Table
                                bordered
                                size="sm"
                                style={{
                                    marginTop: "1rem",
                                    border: "2px solid black",
                                    borderCollapse: "collapse",
                                    width: "30%", // Define a largura da tabela para 30% do contêiner
                                }}
                            >
                                <tbody>
                                    <tr>
                                        <td style={{ textAlign: "right", fontWeight: "bold" }}>Total Geral:</td>
                                        <td style={{ textAlign: "right", fontWeight: "bold" }}>
                                            {formatBRL(
                                                groupedPaymentsPrestacaoContas.reduce((acc, g) => acc + g.totalAmount, 0)
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "right", fontWeight: "bold" }}>Total Geral em Depósito:</td>
                                        <td style={{ textAlign: "right", fontWeight: "bold" }}>
                                            {formatBRL(totalDepositPrestacaoContas)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "right", fontWeight: "bold" }}>Total Geral em Espécie:</td>
                                        <td style={{ textAlign: "right", fontWeight: "bold" }}>
                                            {formatBRL(totalCashPrestacaoContas)}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                            {/* Fim da Tabela de Totais Gerais */}

                            <Button
                                variant="success"
                                onClick={handleGeneratePdfPrestacaoContas}
                                style={{ marginTop: "1rem" }}
                            >
                                Gerar PDF
                            </Button>
                        </>
                    )}
                </Tab>
            </Tabs>
        </div>
    );

};

export default ReportPayments;
