import React, { useState, useEffect, useMemo } from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import UserService from '../app/service/userService';
import { messageError } from './toastr';
import RolePermissionService from '../app/service/rolePermissionService';
import MemberService from '../app/service/memberService';
import * as Constant from '../config/constants';

function NavBar() {
    const location = useLocation();
    const [logged, setLogged] = useState(false);
    const [visible, setVisible] = useState(false);
    const [visibleAdmin, setVisibleAdmin] = useState(false);
    const navigate = useNavigate();
    const userService = useMemo(() => new UserService(), []);
    const memberService = useMemo(() => new MemberService(), []);
    const rolePermissionService = useMemo(() => new RolePermissionService(), []);
    const [canViewHome, setCanViewHome] = useState(false);
    const [canViewActivities, setCanViewActivities] = useState(false);
    const [canViewBoardMembers, setCanViewBoardMembers] = useState(false);
    const [canViewCalendar, setCanViewCalendar] = useState(false);
    const [canViewMemberList, setCanViewMemberList] = useState(false);
    const [canViewSpouseList, setCanViewSpouseList] = useState(false);
    const [canViewChildrenList, setCanViewChildrenList] = useState(false);
    const [canViewReport, setCanViewReport] = useState(false);
    const [canViewVisitorList, setCanViewVisitorList] = useState(false);
    const [canViewTreasury, setCanViewTreasury] = useState(false);



    const isActive = (path) => location.pathname === path;
    const isAnyMemberActive = () => isActive('/member-list') || isActive('/spouse-list') || isActive('/children-list');

    const activeStyle = {
        color: 'white',
        fontWeight: 'bold',
    };

    const inactiveStyle = {
        color: 'black',
        fontWeight: 'bold'
    };

    const membersTitleStyle = {
        fontWeight: 'bold',
        color: isAnyMemberActive() ? 'white' : 'black'
    };

    const Logout = async () => {
        localStorage.removeItem("token");
        ClearCache()
        window.location.reload()
        return;
    }

    const ClearCache = async () => {
        localStorage.removeItem("user-edit");
        localStorage.removeItem("member-edit");
        localStorage.removeItem("spouse-edit");
        localStorage.removeItem("children-edit");
        localStorage.removeItem("visitor-edit");
        localStorage.removeItem("event-edit");
        localStorage.removeItem("eventType-edit");
    }

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem("token");

            if (token) {

                const login = await userService.validate(token);
                if (!login.data) {
                    return;
                } else {
                    let bAdmin = false;
                    const user = await userService.getByToken(token);
                    if (user) {
                        if (user.data.role === 1) {
                            bAdmin = true;

                        } else {
                            const responseMember = await memberService.getById(user.data.member);

                            if (user.data.role !== 1 && responseMember.data.lodgeRole !== 1) {

                                let temp = await rolePermissionService.hasPermission(responseMember.data.lodgeRole, Constant.VIEW_SCREEN_HOME);
                                setCanViewHome(temp.data);

                                temp = await rolePermissionService.hasPermission(responseMember.data.lodgeRole, Constant.VIEW_SCREEN_ACTIVITIES);
                                setCanViewActivities(temp.data);

                                temp = await rolePermissionService.hasPermission(responseMember.data.lodgeRole, Constant.VIEW_SCREEN_BOARD);
                                setCanViewBoardMembers(temp.data);

                                temp = await rolePermissionService.hasPermission(responseMember.data.lodgeRole, Constant.VIEW_SCREEN_CALENDAR);
                                setCanViewCalendar(temp.data);

                                temp = await rolePermissionService.hasPermission(responseMember.data.lodgeRole, Constant.VIEW_SCREEN_MEMBER_LIST);
                                setCanViewMemberList(temp.data);

                                temp = await rolePermissionService.hasPermission(responseMember.data.lodgeRole, Constant.VIEW_SCREEN_SPOUSE_LIST);
                                setCanViewSpouseList(temp.data);

                                temp = await rolePermissionService.hasPermission(responseMember.data.lodgeRole, Constant.VIEW_SCREEN_CHILDREN_LIST);
                                setCanViewChildrenList(temp.data);

                                temp = await rolePermissionService.hasPermission(responseMember.data.lodgeRole, Constant.VIEW_SCREEN_REPORT);
                                setCanViewReport(temp.data);

                                temp = await rolePermissionService.hasPermission(responseMember.data.lodgeRole, Constant.VIEW_SCREEN_VISITOR_LIST);
                                setCanViewVisitorList(temp.data);

                                temp = await rolePermissionService.hasPermission(responseMember.data.lodgeRole, Constant.VIEW_SCREEN_TREASURY);
                                setCanViewTreasury(temp.data);

                            } else {
                                bAdmin = true;
                            }
                        }

                    }

                    if (bAdmin) {
                        setVisibleAdmin(true)
                        setCanViewHome(true)
                        setCanViewActivities(true)
                        setCanViewBoardMembers(true)
                        setCanViewCalendar(true)
                        setCanViewMemberList(true)
                        setCanViewSpouseList(true)
                        setCanViewChildrenList(true)
                        setCanViewReport(true)
                        setCanViewVisitorList(true)
                        setCanViewTreasury(true)
                    }
                    setLogged(true)

                }



            } else {
                let temp = await rolePermissionService.hasPermission(Constant.PUBLIC_ACCESS, Constant.VIEW_SCREEN_HOME);
                setCanViewHome(temp.data);

                temp = await rolePermissionService.hasPermission(Constant.PUBLIC_ACCESS, Constant.VIEW_SCREEN_ACTIVITIES);
                setCanViewActivities(temp.data);

                temp = await rolePermissionService.hasPermission(Constant.PUBLIC_ACCESS, Constant.VIEW_SCREEN_BOARD);
                setCanViewBoardMembers(temp.data);

                temp = await rolePermissionService.hasPermission(Constant.PUBLIC_ACCESS, Constant.VIEW_SCREEN_CALENDAR);
                setCanViewCalendar(temp.data);

                temp = await rolePermissionService.hasPermission(Constant.PUBLIC_ACCESS, Constant.VIEW_SCREEN_MEMBER_LIST);
                setCanViewMemberList(temp.data);

                temp = await rolePermissionService.hasPermission(Constant.PUBLIC_ACCESS, Constant.VIEW_SCREEN_SPOUSE_LIST);
                setCanViewSpouseList(temp.data);

                temp = await rolePermissionService.hasPermission(Constant.PUBLIC_ACCESS, Constant.VIEW_SCREEN_CHILDREN_LIST);
                setCanViewChildrenList(temp.data);

                temp = await rolePermissionService.hasPermission(Constant.PUBLIC_ACCESS, Constant.VIEW_SCREEN_REPORT);
                setCanViewReport(temp.data);

                temp = await rolePermissionService.hasPermission(Constant.PUBLIC_ACCESS, Constant.VIEW_SCREEN_VISITOR_LIST);
                setCanViewVisitorList(temp.data);
                return;
            }
        }
        fetchData();
    }, [navigate, userService]);

    return (
        <Navbar collapseOnSelect expand="lg" style={{ backgroundColor: '#b3e0ff', padding: '5px 15px' }} fixed="top">
            <Container>
                <Navbar.Brand onClick={ClearCache} href="/">
                    <img
                        src="/LMPHB_logo.png"
                        height="50"
                        className="d-inline-block align-top"
                        alt="Herminio Blackman Logo"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto" style={{ display: 'flex', alignItems: 'center', gap: '30px' }}>
                        {canViewHome && (
                            <Nav.Link
                                onClick={ClearCache}
                                href="/home"
                                style={{
                                    textAlign: "center",
                                    ...(isActive('/home') ? activeStyle : inactiveStyle)
                                }}
                            >
                                L∴M∴P∴H∴B∴
                            </Nav.Link>
                        )}
                        {canViewActivities && (
                            <Nav.Link onClick={ClearCache} href="/activities" style={{ ...isActive('/activities') ? activeStyle : inactiveStyle, display: 'flex', alignItems: 'center' }}>Atividades</Nav.Link>
                        )}
                        {canViewBoardMembers && (
                            <Nav.Link onClick={ClearCache} href="/board-members" style={{ ...isActive('/board-members') ? activeStyle : inactiveStyle, display: 'flex', alignItems: 'center' }}>Gestão</Nav.Link>
                        )}
                        {canViewCalendar && (
                            <Nav.Link onClick={ClearCache} href="/calendar" style={{ ...isActive('/calendar') ? activeStyle : inactiveStyle, display: 'flex', alignItems: 'center' }}>Calendário</Nav.Link>
                        )}
                        {(canViewMemberList || canViewSpouseList || canViewChildrenList) && (
                            <NavDropdown
                                title={<span style={membersTitleStyle}>Membros</span>}
                                id="members-dropdown"
                                className="custom-nav-dropdown"
                                style={{ display: 'flex', alignItems: 'center' }}
                            >
                                {canViewMemberList && (
                                    <NavDropdown.Item onClick={ClearCache} href="/member-list" style={isActive('/member-list') ? activeStyle : inactiveStyle}>Irmãos</NavDropdown.Item>
                                )}
                                {canViewSpouseList && (
                                    <NavDropdown.Item onClick={ClearCache} href="/spouse-list" style={isActive('/spouse-list') ? activeStyle : inactiveStyle}>Cunhadas</NavDropdown.Item>
                                )}
                                {canViewChildrenList && (
                                    <NavDropdown.Item onClick={ClearCache} href="/children-list" style={isActive('/children-list') ? activeStyle : inactiveStyle}>Sobrinhos</NavDropdown.Item>
                                )}
                            </NavDropdown>
                        )}

                        {canViewReport && (
                            <Nav.Link onClick={ClearCache} href="/report" style={{ ...isActive('/report') ? activeStyle : inactiveStyle, display: 'flex', alignItems: 'center' }}>Relatórios</Nav.Link>
                        )}
                        {canViewVisitorList && (
                            < Nav.Link onClick={ClearCache} href="/visitor-list" style={isActive('/visitor-list') ? activeStyle : inactiveStyle}>Visitantes</Nav.Link>
                        )}
                        {canViewTreasury && (
                            <Nav.Link onClick={ClearCache} href="/tesouraria" style={isActive('/tesouraria') ? activeStyle : inactiveStyle}>Tesouraria</Nav.Link>
                        )}
                    </Nav>
                    <br />
                    <Nav className="ml-auto nav-separator" style={{ display: 'flex', alignItems: 'center', gap: '30px' }}>
                        {logged ? (
                            <>
                                <Nav.Link onClick={Logout} href="/home" style={inactiveStyle}>Sair</Nav.Link>
                                <NavDropdown
                                    title={<span style={isActive('/user-list') ? activeStyle : inactiveStyle}><FontAwesomeIcon icon={faGear} /></span>}
                                    id="user-dropdown"
                                    className="custom-nav-dropdown"
                                >
                                    {logged ? (
                                        <>
                                            {visibleAdmin ? (
                                                <>
                                                    <NavDropdown.Item onClick={ClearCache} href="/config" style={isActive('/config') ? activeStyle : inactiveStyle}>Configurações</NavDropdown.Item>
                                                    <NavDropdown.Item onClick={ClearCache} href="/user-list" style={isActive('/user-list') ? activeStyle : inactiveStyle}>Alterar Usuários</NavDropdown.Item>
                                                </>
                                            ) : (
                                                <NavDropdown.Item onClick={ClearCache} href="/user-register" style={isActive('/user-register') ? activeStyle : inactiveStyle}>Alterar Usuário</NavDropdown.Item>
                                            )}
                                        </>
                                    ) : (
                                        <Nav.Link onClick={ClearCache} href="/login" style={isActive('/login') ? activeStyle : inactiveStyle}>Entrar</Nav.Link>
                                    )}
                                </NavDropdown>
                            </>
                        ) : (
                            <Nav.Link onClick={ClearCache} href="/login" style={inactiveStyle}>Entrar</Nav.Link>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar >


    );
}

export default NavBar;
