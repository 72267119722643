// taxesReferenceService.js

import ApiService from '../apiservice';

class TaxesReferenceService extends ApiService {

    constructor() {
        super('/taxes-reference');
    }

    getAll() {
        return this.get(`${this.apiurl}/all`);
    }

    getByYear(year) {
        return this.get(`${this.apiurl}/by-year/${year}`);
    }

    getByTaxIdAndYear(taxId, year) {
        return this.get(`${this.apiurl}/by-tax-year/${taxId}/${year}`);
    }

    new(taxRef) {
        return this.post(`${this.apiurl}/new`, taxRef);
    }

    update(taxRef) {
        return this.put(`${this.apiurl}/${taxRef.id}`, taxRef);
    }

    delete(id) {
        return super.delete(`${this.apiurl}/${id}`);
    }
}

export default TaxesReferenceService;
