import React, { useState, useEffect, useMemo, useContext } from "react";
import { Table, Form, Modal, Button, Spinner } from "react-bootstrap";
import SelectMenu from "../../components/selectMenu";
import { messageError, messageSuccess } from "../../components/toastr";
import TaxesPaymentsService from "../../app/service/taxesPaymentsService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AuthContext } from "../../security/AuthContext";
import MemberService from "../../app/service/memberService";

const TaxPayments = ({ taxes, members }) => {
    const { user, isAuthenticated } = useContext(AuthContext);
    const [selectedMember, setSelectedMember] = useState("");
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [filteredPayments, setFilteredPayments] = useState([]);
    const [viewAllMembers, setViewAllMembers] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [paymentDate, setPaymentDate] = useState(new Date());
    const [receipt, setReceipt] = useState("");
    const [paymentType, setPaymentType] = useState("Depósito"); // Valor padrão definido
    const [membersStatus, setMembersStatus] = useState([]);
    const [loadingConfirm, setLoadingConfirm] = useState(false);
    const [loading, setLoading] = useState(false);

    // Estados para pagamento múltiplo
    const [showBulkModal, setShowBulkModal] = useState(false);
    const [bulkPaymentDate, setBulkPaymentDate] = useState(new Date());
    const [bulkReceipt, setBulkReceipt] = useState("");
    const [bulkPaymentType, setBulkPaymentType] = useState("Depósito"); // Valor padrão definido

    const [pendingPayments, setPendingPayments] = useState([]); // Lista de pendentes
    const [selectedPendingIds, setSelectedPendingIds] = useState([]); // IDs marcados

    const taxesPaymentsService = useMemo(() => new TaxesPaymentsService(), []);
    const memberService = useMemo(() => new MemberService(), []);

    const yearOptions = useMemo(() => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let i = currentYear + 1; i >= currentYear - 5; i--) {
            years.push(i);
        }
        years.sort((a, b) => a - b);
        return years;
    }, []);

    // Verifica se cada membro está inadimplente (para exibir no select)
    const checkAllMembersOverdue = async () => {
        if (!members || members.length === 0) {
            setMembersStatus([]);
            return;
        }

        const today = new Date();
        const statusPromises = members.map(async (member) => {
            if (!member.id) return { id: member.id, overdue: false };

            try {
                const resp = await taxesPaymentsService.filterPayments(member.id, selectedYear);
                const payments = resp.data || [];
                const isOverdue = payments.some((p) => {
                    const expireDate = p.dtExpire ? new Date(p.dtExpire) : null;
                    return expireDate && expireDate < today && !p.dtPayment;
                });
                return { id: member.id, overdue: isOverdue };
            } catch {
                return { id: member.id, overdue: false };
            }
        });

        const result = await Promise.all(statusPromises);
        setMembersStatus(result);
    };

    const fetchFilteredPayments = async () => {
        try {
            setLoading(true);
            if (!selectedMember) {
                setFilteredPayments([]);
                return;
            }
            const response = await taxesPaymentsService.filterPayments(selectedMember, selectedYear);
            const payments = response.data || [];
            const convertedPayments = payments.map((payment) => ({
                ...payment,
                dtExpire: payment.dtExpire ? new Date(payment.dtExpire) : null,
                dtPayment: payment.dtPayment ? new Date(payment.dtPayment) : null,
            }));
            setFilteredPayments(convertedPayments);
        } catch (error) {
            console.error("Erro ao buscar pagamentos:", error);
            const errorMessage = error.response?.data || "Ocorreu um erro ao buscar pagamentos.";
            messageError(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (isAuthenticated) {
                if (user) {
                    // Se não é admin, busca o ID do membro e força a seleção
                    if (user.role !== 1) {
                        const responseMember = await memberService.getById(user.member);
                        setViewAllMembers(responseMember.data.lodgeRole === 6);

                        if (selectedMember === "") {
                            setSelectedMember(responseMember.data.id);
                        }
                    } else {
                        setViewAllMembers(true);
                    }
                }
            }

            // Verifica inadimplência dos membros
            if (members && members.length > 0 && selectedYear) {
                checkAllMembersOverdue();
            }

            // Carrega pagamentos do membro selecionado
            if (selectedMember && selectedYear) {
                setLoading(true);
                setFilteredPayments([]);
                try {
                    const response = await taxesPaymentsService.filterPayments(selectedMember, selectedYear);
                    const payments = response.data || [];
                    const convertedPayments = payments.map((payment) => ({
                        ...payment,
                        dtExpire: payment.dtExpire ? new Date(payment.dtExpire) : null,
                        dtPayment: payment.dtPayment ? new Date(payment.dtPayment) : null,
                    }));
                    setFilteredPayments(convertedPayments);
                } catch (error) {
                    console.error("Erro ao buscar pagamentos:", error);
                    const errorMessage =
                        error.response?.data || "Ocorreu um erro ao buscar pagamentos.";
                    messageError(errorMessage);
                } finally {
                    setLoading(false);
                }
            } else {
                setFilteredPayments([]);
            }
        };

        fetchData();
    }, [members, selectedYear, selectedMember, isAuthenticated, user]);

    // Define a cor das células da tabela (pagamentos individuais)
    const getTextColor = (payment) => {
        if (!payment || !payment.dtExpire) return "black";
        const today = new Date();
        if (payment.dtPayment) return "green";
        if (payment.dtExpire < today) return "red";
        return "black";
    };

    // Ao clicar em uma célula, abre o modal de pagamento individual
    const handleCellClick = (payment) => {
        if (!payment) return;
        setSelectedPayment(payment);
        setPaymentDate(payment?.dtExpire || new Date());
        setReceipt("");
        setPaymentType("Depósito"); // Resetar para valor padrão
        setShowModal(true);
    };

    // Confirma pagamento individual
    const handleConfirmPayment = async () => {
        if (!selectedPayment) return;
        try {
            setLoadingConfirm(true);
            const updatedPayment = {
                ...selectedPayment,
                dtPayment: paymentDate,
                receipt: receipt,
                paymentType: paymentType,
            };
            await taxesPaymentsService.updatePayment(selectedPayment.id, updatedPayment);
            messageSuccess("Pagamento atualizado com sucesso!");
            setShowModal(false);
            setLoading(true);
            fetchFilteredPayments();
        } catch (error) {
            console.error("Erro ao atualizar pagamento:", error);
            const errorMessage =
                error.response?.data || "Ocorreu um erro ao atualizar o pagamento.";
            messageError(errorMessage);
        } finally {
            setLoadingConfirm(false);
        }
    };

    // Exclui pagamento individual
    const handleDeletePayment = async () => {
        if (!selectedPayment || !selectedPayment.id) return;
        try {
            await taxesPaymentsService.deletePayment(selectedPayment.id);
            messageSuccess("Pagamento removido com sucesso!");
            setShowDeleteModal(false);
            setShowModal(false);
            setLoading(true);
            fetchFilteredPayments();
        } catch (error) {
            console.error("Erro ao remover pagamento:", error);
            const errorMessage =
                error.response?.data || "Ocorreu um erro ao remover o pagamento.";
            messageError(errorMessage);
        }
    };

    // ------------------------------------------------------------------
    // Pagamento Múltiplo
    // ------------------------------------------------------------------
    const openBulkModal = () => {
        // Filtra payments ainda não pagos
        const pending = filteredPayments.filter((p) => !p.dtPayment && p.amount > 0);

        // Ordena pelo vencimento (mais antigo primeiro)
        pending.sort((a, b) => {
            const aTime = a.dtExpire ? a.dtExpire.getTime() : Infinity;
            const bTime = b.dtExpire ? b.dtExpire.getTime() : Infinity;
            return aTime - bTime;
        });

        setPendingPayments(pending);
        setSelectedPendingIds([]);
        setBulkPaymentDate(new Date());
        setBulkReceipt("");
        setBulkPaymentType("Depósito"); // Resetar para valor padrão
        setShowBulkModal(true);
    };

    const toggleSelectPending = (paymentId) => {
        setSelectedPendingIds((prev) => {
            if (prev.includes(paymentId)) {
                return prev.filter((id) => id !== paymentId);
            } else {
                return [...prev, paymentId];
            }
        });
    };

    const handleBulkPayment = async () => {
        if (selectedPendingIds.length === 0) {
            messageError("Nenhum pagamento foi selecionado!");
            return;
        }
        try {
            setLoadingConfirm(true);
            for (const payId of selectedPendingIds) {
                const paymentToUpdate = pendingPayments.find((p) => p.id === payId);
                if (paymentToUpdate) {
                    const updatedPayment = {
                        ...paymentToUpdate,
                        dtPayment: bulkPaymentDate,
                        receipt: bulkReceipt,
                        paymentType: bulkPaymentType,
                    };
                    await taxesPaymentsService.updatePayment(payId, updatedPayment);
                }
            }
            messageSuccess("Pagamentos múltiplos realizados com sucesso!");
            setShowBulkModal(false);
            setLoading(true);
            fetchFilteredPayments();
        } catch (err) {
            console.error("Erro ao pagar múltiplos:", err);
            const errorMessage =
                err.response?.data || "Ocorreu um erro ao pagar múltiplos pagamentos.";
            messageError(errorMessage);
        } finally {
            setLoadingConfirm(false);
        }
    };

    // ------------------------------------------------------------------
    // Monta lista de membros (para o select)
    // ------------------------------------------------------------------
    const membersList = useMemo(() => {
        if (!members || members.length === 0) {
            return [{ label: "Selecione", value: "" }];
        }
        return [
            { label: "Selecione", value: "" },
            ...members.map((member) => {
                const status = membersStatus.find((s) => s.id === member.id);
                const overdue = status && status.overdue;
                return {
                    label: member.name + (overdue ? " - INADIMPLENTE" : ""),
                    value: member.id,
                };
            }),
        ];
    }, [members, membersStatus]);

    // ------------------------------------------------------------------
    // Agrupamento e Totais
    // ------------------------------------------------------------------
    const groupedData = useMemo(() => {
        const groups = {};
        if (!filteredPayments || !Array.isArray(filteredPayments)) return groups;
        if (!taxes || !Array.isArray(taxes)) return groups;

        const paymentsInSelectedYear = filteredPayments.filter((payment) => {
            return payment.dtExpire && payment.dtExpire.getFullYear() === selectedYear;
        });

        paymentsInSelectedYear.forEach((payment) => {
            const taxGroup = payment.tax ? payment.tax.taxGroup : "Outros";
            const displayDescription = payment.description
                ? payment.description
                : payment.tax
                    ? payment.tax.description
                    : "N/A";

            if (!groups[taxGroup]) {
                groups[taxGroup] = {};
            }
            if (!groups[taxGroup][displayDescription]) {
                groups[taxGroup][displayDescription] = {
                    taxId: payment.tax ? payment.tax.id : null,
                    months: Array(12).fill(null),
                };
            }

            if (payment.dtExpire) {
                const month = payment.dtExpire.getMonth();
                groups[taxGroup][displayDescription].months[month] = payment;
            }
        });

        // Remove descrições vazias
        Object.keys(groups).forEach((group) => {
            groups[group] = Object.fromEntries(
                Object.entries(groups[group]).filter(([_, data]) =>
                    data.months.some((month) => month !== null)
                )
            );
            if (Object.keys(groups[group]).length === 0) {
                delete groups[group];
            }
        });

        return groups;
    }, [filteredPayments, taxes, selectedYear]);

    // Total mensal geral
    const monthlyTotals = useMemo(() => {
        const totals = Array(12).fill(0);
        Object.keys(groupedData).forEach((group) => {
            const descriptions = groupedData[group];
            Object.values(descriptions).forEach((data) => {
                data.months.forEach((payment, i) => {
                    if (payment && payment.amount > 0) {
                        totals[i] += payment.amount;
                    }
                });
            });
        });
        return totals;
    }, [groupedData]);

    // Total mensal pago
    const monthlyTotalsPaid = useMemo(() => {
        const totals = Array(12).fill(0);
        Object.keys(groupedData).forEach((group) => {
            const descriptions = groupedData[group];
            Object.values(descriptions).forEach((data) => {
                data.months.forEach((payment, i) => {
                    if (payment && payment.amount > 0 && payment.dtPayment) {
                        totals[i] += payment.amount;
                    }
                });
            });
        });
        return totals;
    }, [groupedData]);

    // Total mensal pendente (sem dtPayment)
    const monthlyTotalsPending = useMemo(() => {
        const totals = Array(12).fill(0);
        Object.keys(groupedData).forEach((group) => {
            const descriptions = groupedData[group];
            Object.values(descriptions).forEach((data) => {
                data.months.forEach((payment, i) => {
                    if (payment && payment.amount > 0 && !payment.dtPayment) {
                        totals[i] += payment.amount;
                    }
                });
            });
        });
        return totals;
    }, [groupedData]);

    // Verifica se, em determinado mês, há pagamento pendente vencido
    const isMonthOverdue = (monthIndex) => {
        const now = new Date();
        for (const groupKey of Object.keys(groupedData)) {
            const descriptions = groupedData[groupKey];
            for (const data of Object.values(descriptions)) {
                const payment = data.months[monthIndex];
                if (payment && !payment.dtPayment && payment.dtExpire < now) {
                    return true;
                }
            }
        }
        return false;
    };

    return (
        <div>
            {/* Seletor de Membro e Ano */}
            <div
                style={{
                    display: "flex",
                    alignItems: "left",
                    gap: "20px",
                    marginBottom: "20px",
                    width: "50%",
                }}
            >
                <Form.Group controlId="selectMember" style={{ flex: 1 }}>
                    <Form.Label>Irmão:</Form.Label>
                    <SelectMenu
                        style={{ width: "100%", height: "38px" }}
                        className="form-control"
                        list={membersList}
                        value={selectedMember}
                        enabled={viewAllMembers}
                        disabled={!viewAllMembers}
                        onChange={(e) => {
                            const val = e.target.value;
                            setSelectedMember(val ? parseInt(val, 10) : "");
                        }}
                    />
                </Form.Group>

                <Form.Group controlId="selectYear" style={{ flex: 0.2 }}>
                    <Form.Label>Ano:</Form.Label>
                    <SelectMenu
                        style={{ width: "100%", height: "38px" }}
                        className="form-control"
                        list={yearOptions.map((year) => ({
                            label: year.toString(),
                            value: year,
                        }))}
                        value={selectedYear}
                        onChange={(e) => setSelectedYear(parseInt(e.target.value, 10))}
                    />
                </Form.Group>
            </div>

            {/* Botão de pagamento múltiplo (caso haja pendências) */}

            {viewAllMembers && filteredPayments.some((p) => !p.dtPayment && p.amount > 0) && (
                <Button variant="warning" onClick={openBulkModal}>
                    Confirmar Pagamentos
                </Button>
            )}

            <div style={{ marginTop: "20px" }}>
                {loading ? (
                    <div style={{ textAlign: "center", marginTop: "20px" }}>
                        <Spinner animation="border" role="status" />
                        <div>Carregando...</div>
                    </div>
                ) : filteredPayments.length > 0 ? (
                    <>
                        {/* Tabela principal */}
                        <Table
                            striped
                            bordered
                            hover
                            size="sm"
                            style={{ border: "3px solid black", borderCollapse: "collapse" }}
                        >
                            <thead>
                                <tr>
                                    <th>Fonte</th>
                                    <th>Descrição</th>
                                    {[...Array(12).keys()].map((i) => (
                                        <th key={i} style={{ textAlign: "center" }}>
                                            {new Date(0, i)
                                                .toLocaleString("pt-BR", { month: "long" })
                                                .toUpperCase()}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(groupedData)
                                    .sort((a, b) => a.localeCompare(b))
                                    .map((group) => {
                                        const descriptions = groupedData[group];
                                        const sortedDescriptions = Object.entries(descriptions).sort(
                                            (a, b) => a[1].taxId - b[1].taxId
                                        );

                                        return (
                                            <React.Fragment key={group}>
                                                {sortedDescriptions.map(([description, data], index) => (
                                                    <tr key={`${group}-${description}`}>
                                                        {index === 0 && (
                                                            <td
                                                                rowSpan={sortedDescriptions.length}
                                                                style={{
                                                                    textAlign: "center",
                                                                    verticalAlign: "middle",
                                                                }}
                                                            >
                                                                {group}
                                                            </td>
                                                        )}
                                                        <td className="align-middle">{description}</td>
                                                        {data.months.map((payment, i) => (
                                                            <td
                                                                key={i}
                                                                style={{
                                                                    textAlign: "center",
                                                                    color: getTextColor(payment),
                                                                    cursor:
                                                                        payment && viewAllMembers
                                                                            ? "pointer"
                                                                            : "default",
                                                                }}
                                                                onClick={
                                                                    viewAllMembers && payment
                                                                        ? () => handleCellClick(payment)
                                                                        : undefined
                                                                }
                                                            >
                                                                {payment?.amount > 0
                                                                    ? Intl.NumberFormat("pt-BR", {
                                                                        style: "currency",
                                                                        currency: "BRL",
                                                                    }).format(payment.amount)
                                                                    : ""}
                                                                <br />
                                                                {payment?.dtPayment
                                                                    ? payment.dtPayment.toLocaleDateString("pt-BR")
                                                                    : payment?.dtExpire
                                                                        ? payment.dtExpire.toLocaleDateString("pt-BR")
                                                                        : ""}
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </React.Fragment>
                                        );
                                    })}

                                {/* Linha Total (valor total geral) */}
                                <tr style={{ borderTop: "3px solid black" }}>
                                    <td
                                        colSpan={2}
                                        style={{ fontWeight: "bold", textAlign: "center" }}
                                    >
                                        Total
                                    </td>
                                    {monthlyTotals.map((total, i) => (
                                        <td
                                            key={i}
                                            style={{ textAlign: "center", fontWeight: "bold" }}
                                        >
                                            {total > 0
                                                ? Intl.NumberFormat("pt-BR", {
                                                    style: "currency",
                                                    currency: "BRL",
                                                }).format(total)
                                                : ""}
                                        </td>
                                    ))}
                                </tr>

                                {/* Linha Total Pago (verde) */}
                                <tr>
                                    <td
                                        colSpan={2}
                                        style={{
                                            fontWeight: "bold",
                                            textAlign: "center",
                                            color: "green",
                                        }}
                                    >
                                        Total pago
                                    </td>
                                    {monthlyTotalsPaid.map((total, i) => (
                                        <td
                                            key={i}
                                            style={{
                                                textAlign: "center",
                                                fontWeight: "bold",
                                                color: "green",
                                            }}
                                        >
                                            {total > 0
                                                ? Intl.NumberFormat("pt-BR", {
                                                    style: "currency",
                                                    currency: "BRL",
                                                }).format(total)
                                                : ""}
                                        </td>
                                    ))}
                                </tr>

                                {/* Linha Total Pendente (preto, mas vermelho se houver vencido no mês) */}
                                <tr style={{ borderBottom: "3px solid black" }}>
                                    <td
                                        colSpan={2}
                                        style={{ fontWeight: "bold", textAlign: "center" }}
                                    >
                                        Total pendente
                                    </td>
                                    {monthlyTotalsPending.map((total, i) => {
                                        const overdue = isMonthOverdue(i);
                                        return (
                                            <td
                                                key={i}
                                                style={{
                                                    textAlign: "center",
                                                    fontWeight: "bold",
                                                    color: overdue ? "red" : "black",
                                                }}
                                            >
                                                {total > 0
                                                    ? Intl.NumberFormat("pt-BR", {
                                                        style: "currency",
                                                        currency: "BRL",
                                                    }).format(total)
                                                    : ""}
                                            </td>
                                        );
                                    })}
                                </tr>
                            </tbody>
                        </Table>

                        {/* Legenda no fim da tabela */}
                        <div style={{ marginTop: "20px" }}>
                            <p>
                                <span style={{ color: "green" }}>●</span> Pago <br />
                                <span style={{ color: "red" }}>●</span> Atrasado <br />
                                <span style={{ color: "black" }}>●</span> Em aberto
                            </p>
                        </div>
                    </>
                ) : (
                    <p>Nenhum pagamento encontrado para os critérios selecionados.</p>
                )}
            </div>

            {/* Modal de pagamento individual */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Pagamento Realizado?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="paymentDate">
                        <Form.Label>Data do Pagamento:</Form.Label>
                        <DatePicker
                            selected={paymentDate}
                            onChange={(date) => setPaymentDate(date)}
                            dateFormat="dd/MM/yyyy"
                            className="form-control"
                        />
                    </Form.Group>
                    <br />
                    <Form.Group controlId="receiptNumber">
                        <Form.Label>Número do Recibo</Form.Label>
                        <Form.Control
                            type="text"
                            value={receipt}
                            onChange={(e) => setReceipt(e.target.value)}
                            placeholder="Informe o número do recibo"
                        />
                    </Form.Group>
                    <br />
                    <Form.Group controlId="paymentType">
                        <Form.Label>Tipo Monetário</Form.Label>
                        <Form.Control
                            as="select"
                            value={paymentType}
                            onChange={(e) => setPaymentType(e.target.value)}
                        >
                            <option value="Depósito">Depósito</option>
                            <option value="Em espécie">Em espécie</option>
                        </Form.Control>
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={() => setShowDeleteModal(true)}>
                        Excluir
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleConfirmPayment}
                        disabled={loadingConfirm}
                    >
                        {loadingConfirm ? "Confirmando..." : "Confirmar"}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal de confirmação da exclusão */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar Exclusão</Modal.Title>
                </Modal.Header>
                <Modal.Body>Tem certeza que deseja excluir este pagamento?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={handleDeletePayment}>
                        Excluir
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal de Pagamento Múltiplo */}
            <Modal show={showBulkModal} onHide={() => setShowBulkModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar Pagamento</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: "70vh", overflowY: "auto" }}>
                    <Form.Group controlId="bulkPaymentDate">
                        <Form.Label>Data do Pagamento:</Form.Label>
                        <DatePicker
                            selected={bulkPaymentDate}
                            onChange={(date) => setBulkPaymentDate(date)}
                            dateFormat="dd/MM/yyyy"
                            className="form-control"
                        />
                    </Form.Group>
                    <br />
                    <Form.Group controlId="bulkReceipt">
                        <Form.Label>Número do Recibo</Form.Label>
                        <Form.Control
                            type="text"
                            value={bulkReceipt}
                            onChange={(e) => setBulkReceipt(e.target.value)}
                            placeholder="Informe o número do recibo"
                        />
                    </Form.Group>
                    <br />
                    <Form.Group controlId="bulkPaymentType">
                        <Form.Label>Tipo Monetário</Form.Label>
                        <Form.Control
                            as="select"
                            value={bulkPaymentType}
                            onChange={(e) => setBulkPaymentType(e.target.value)}
                        >
                            <option value="Depósito">Depósito</option>
                            <option value="Em espécie">Em espécie</option>
                        </Form.Control>
                    </Form.Group>
                    <br />
                    {pendingPayments.length === 0 && (
                        <p>Não há pagamentos pendentes para este membro.</p>
                    )}
                    {/* Checkbox no estilo do AttendanceDT */}
                    {pendingPayments.map((payment) => {
                        const labelText = `
${payment.tax?.taxGroup} - ${payment.tax?.description || payment.description}
- Venc: ${payment.dtExpire ? payment.dtExpire.toLocaleDateString("pt-BR") : ""}
- Valor: ${payment.amount
                                ? Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(payment.amount)
                                : ""
                            }
            `;
                        return (
                            <div
                                key={payment.id}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    marginBottom: "-2vh"
                                }}
                            >
                                <input
                                    type="checkbox"
                                    style={{ transform: "scale(2.2)", cursor: "pointer" }}
                                    checked={selectedPendingIds.includes(payment.id)}
                                    onChange={() => toggleSelectPending(payment.id)}
                                />
                                <span style={{ whiteSpace: "pre-wrap", marginLeft: "20px" }}>
                                    {labelText}
                                </span>
                            </div>
                        );
                    })}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowBulkModal(false)}>
                        Cancelar
                    </Button>
                    <Button variant="success" onClick={handleBulkPayment} disabled={loadingConfirm}>
                        {loadingConfirm ? "Confirmando..." : "Confirmar Pagamento"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );

};

export default TaxPayments;
