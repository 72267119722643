// TaxesPaymentsService.js

import ApiService from '../apiservice';

class TaxesPaymentsService extends ApiService {

    constructor() {
        super('/taxes-payments');
    }

    getAll() {
        return this.get(`${this.apiurl}/all`);
    }

    new(payment) {
        return this.post(`${this.apiurl}/new`, payment);
    }

    updatePayment(id, payment) {
        return this.put(`${this.apiurl}/${id}`, payment);
    }

    includeForAll(payment) {
        return this.post(`${this.apiurl}/include/all`, payment);
    }

    includeForOne(payment) {
        return this.post(`${this.apiurl}/include?member=${payment.member}`, payment);
    }

    includeForMultiple(includeMultipleRequest) {
        return this.post(`${this.apiurl}/include/multiple`, includeMultipleRequest);
    }

    filterPayments(memberId, year) {
        return this.get(`${this.apiurl}/filter?member=${memberId}&year=${year}`);
    }

    deletePayment(id) {
        return this.delete(`${this.apiurl}/${id}`);
    }

    isMemberUpToDate(memberId) {
        return this.get(`${this.apiurl}/is-member-up-to-date?member=${memberId}`);
    }

    areMembersUpToDate(memberIds) {
        return this.post(`${this.apiurl}/are-members-up-to-date`, memberIds);
    }

}

export default TaxesPaymentsService;
