import React, { useState, useEffect, useMemo, useContext } from 'react';
import Card from '../../components/card';
import FormGroup from '../../components/form-group';
import FormField from '../../components/form-field';
import { useNavigate } from 'react-router-dom';
import Container from '../../components/container';
import UserService from '../../app/service/userService';
import { messageSuccess, messageError } from '../../components/toastr';
import { format, parse } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import SelectMenu from "../../components/selectMenu";
import DatePicker from 'react-datepicker';
import { AuthContext } from '../../security/AuthContext';
import VisitorService from '../../app/service/visitorService';
import MemberService from '../../app/service/memberService';
import { formatCPF, validateCPF } from '../../utils/cpfUtils.js';
import RolePermissionService from "../../app/service/rolePermissionService";
import * as Constant from '../../config/constants';
import DegreeService from "../../app/service/degreeService.js";

function VisitorRegister(props) {
    const { user, isAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();
    const [visitor, setVisitor] = useState(null);
    const visitorEdit = JSON.parse(localStorage.getItem('visitor-edit'));
    const visitorService = useMemo(() => new VisitorService(), []);
    const userService = useMemo(() => new UserService(), []);
    const memberService = useMemo(() => new MemberService(), []);
    const rolePermissionService = useMemo(() => new RolePermissionService(), []);
    const degreeService = useMemo(() => new DegreeService(), []);
    const [visible, setVisible] = useState(false);
    const [id, setId] = useState(0);
    const [name, setName] = useState('');
    const [cim, setCim] = useState('');
    const [cpf, setCpf] = useState('');
    const [email, setEmail] = useState('');
    const [lodge, setLodge] = useState('');
    const [lodgePotency, setLodgePotency] = useState('');
    const [lodgeState, setLodgeState] = useState('');
    const [lodgeDegree, setLodgeDegree] = useState('');
    const [phoneCell, setPhoneCell] = useState('');
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [cpfError, setCpfError] = useState('');
    const [cimError, setCimError] = useState('');
    const [bEdit, setBEdit] = useState(false);
    const [listDegree, setListDegree] = useState([]);

    const selectOption = { label: 'Selecione', value: '0' };
    const listSN = [
        { label: 'Não', value: 'N' },
        { label: 'Sim', value: 'S' }
    ];

    const validateEmail = (inputEmail) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(inputEmail);
    };

    const handleNameChange = (inputName) => {
        setName(inputName);
        setNameError('');
    };

    const handleEmailChange = (inputEmail) => {
        setEmail(inputEmail);

        if (!validateEmail(inputEmail)) {
            setEmailError('Digite um email válido');
        } else {
            setEmail(inputEmail);
            setEmailError('');
        }
        if (inputEmail === '') {
            setEmail(inputEmail);
            setEmailError('');
        }
    };

    const register = async () => {
        if (name === '') {
            messageError('Nome inválido');
            return;
        }

        if (email === '' || emailError) {
            messageError('Email inválido');
            return;
        }

        if (cpfError !== '') {
            messageError("CPF inválido");
            return;
        }

        if (cimError !== '') {
            messageError("CIM inválido");
            return;
        }

        setNameError('');
        setCpfError('');
        setCimError('');

        if (visitorEdit) {
            await visitorService.update({
                id: id,
                name: name,
                email: email,
                phoneCell: phoneCell,
                cim: cim,
                cpf: cpf,
                lodge: lodge,
                lodgePotency: lodgePotency,
                lodgeState: lodgeState,
                lodgeDegree: lodgeDegree
            }).then(response => {
                messageSuccess('Visitante atualizado com sucesso.');
                localStorage.removeItem('visitor-edit');
                if (props?.onEventSaved && typeof props.onEventSaved === 'function') {
                    props.onEventSaved();
                } else {
                    navigate('/visitor-list');
                }
            }).catch(error => {
                messageError(error.response.data);
            });
        } else {
            await visitorService.new({
                name: name,
                email: email,
                phoneCell: phoneCell,
                cim: cim,
                cpf: cpf,
                lodge: lodge,
                lodgePotency: lodgePotency,
                lodgeState: lodgeState,
                lodgeDegree: lodgeDegree
            }).then(response => {
                messageSuccess('Visitante cadastrado com sucesso.');
                localStorage.removeItem('visitor-edit');
                finalize()
            }).catch(error => {
                messageError(error.response.data);
            });
        }
    };
    const finalize = async () => {

        // Verifica se props existe e se onEventSaved é uma função
        if (props?.onEventSaved && typeof props.onEventSaved === 'function') {
            props.onEventSaved();
        } else {
            navigate('/visitor-list');
        }
    };

    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = phoneNumber.replace(/\D/g, ''); // Remove qualquer caracter não numérico
        const match = cleaned.match(/^(\d{2})(\d{1,5})?(\d{0,4})$/);
        if (match) {
            const part1 = match[1] ? `(${match[1]})` : '';
            const part2 = match[2] ? ` ${match[2]}` : '';
            const part3 = match[3] ? `-${match[3]}` : '';
            return `${part1}${part2}${part3}`;
        }
        return phoneNumber; // Retorna o original se não houver correspondência
    };

    const handlePhoneCellChange = (e) => {
        const formattedPhone = formatPhoneNumber(e.target.value);
        setPhoneCell(formattedPhone); // Assume que você tem um estado chamado `phoneCell` para o telefone celular
    };

    const handleCimChange = async (inputCim) => {
        setCim(inputCim)
        if (inputCim) {

            let lExistCimMember = await memberService.validateCim(inputCim)
            if (lExistCimMember.data < 0) {
                setCimError('Este CIM pertence a um irmão.');
            }

            let lExistCimVisitor = await visitorService.validateCim(inputCim)
            if (lExistCimVisitor.data < 0) {

                const response = await visitorService.getByCim(inputCim);

                if (response.data.id != visitorEdit) {
                    setCimError('CIM já cadastrado.');
                }

            } else {
                setCimError('');
            }
        }
    }

    const handleCpfChange = async (inputCpf) => {
        const formattedCpf = formatCPF(inputCpf);
        setCpf(formattedCpf)
        if (!validateCPF(inputCpf)) {
            setCpfError('Digite um CPF válido');
        } else {
            setCpfError('');

            let lExistCpfMember = await memberService.validateCpf(inputCpf)
            if (lExistCpfMember.data < 0) {
                setCpfError('CPF já cadastrado!');
            }

            let lExistCpfVisitor = await memberService.validateCpf(inputCpf)
            if (lExistCpfVisitor.data < 0 && !visitorEdit) {
                setCpfError('CPF já cadastrado!');
            }
        }
    }

    const handleDegreeChange = (event) => {
        setLodgeDegree(event.target.value);
    };


    useEffect(() => {
        const fetchData = async () => {
            if (isAuthenticated) {
                if (user) {
                    if (user.role !== 1) {
                        const responseMember = await memberService.getById(user.member);
                        const responseCanEdit = await rolePermissionService.hasPermission(responseMember.data.lodgeRole, Constant.EDIT_VISITORS_INFO);
                        if (!responseCanEdit.data) {
                            messageError("Acesso negado. Procure um administrador.")
                            navigate('/login');
                        }
                    }
                }
            } else {
                messageError("Acesso negado. Procure um administrador.")
                navigate('/login');
            }

            if (visitorEdit) {
                setBEdit(true)
                const response = await visitorService.getById(visitorEdit);
                setVisitor(response.data);
                const visitorData = response.data;

                setId(visitorData.id);
                setName(visitorData.name);
                setEmail(visitorData.email);
                setPhoneCell(visitorData.phoneCell)
                setCpf(visitorData.cpf);
                setCim(visitorData.cim);
                setLodge(visitorData.lodge);
                setLodgePotency(visitorData.lodgePotency);
                setLodgeState(visitorData.lodgeState);
                setLodgeDegree(visitorData.lodgeDegree);
            }

            const lodgeDegreeList = await degreeService.getAll();
            const listLodgeDegree = [
                { label: 'Selecione', value: 0 },  // Adiciona o item "Selecione" no início da lista
                ...lodgeDegreeList.data
                    .filter(degree => degree.id <= 3)
                    .map(degree => ({
                        label: `${degree.id} - ${degree.description}`,
                        value: degree.id
                    })),
                { label: '3 - Mestre Maçon Instalado', value: 3 },
            ];

            setListDegree(listLodgeDegree);
        };

        fetchData();

    }, [visitorEdit, navigate, userService, visitorService]);

    return (
        <Container>
            <div >
                <Card title="Cadastro de visitante" >
                    <div >

                        <br />
                        <FormGroup label="Nome: *" htmlFor="inputName" error={nameError}>
                            <input
                                className={`form-control ${nameError ? 'is-invalid' : ''}`}
                                type="text"
                                id="inputName"
                                name="name"
                                value={name}
                                placeholder="Digite o nome"
                                onChange={(e) => handleNameChange(e.target.value)}
                                style={{ maxWidth: '300px' }}
                            />
                            {nameError && (
                                <div className="invalid-feedback">
                                    {nameError}
                                </div>
                            )}
                        </FormGroup>
                        <br />
                        <FormGroup
                            label="CIM: *"
                            htmlFor="inputCim"
                            error={cimError}
                        >
                            <input
                                className={`form-control ${cimError ? 'is-invalid' : ''}`}
                                type="text"
                                id="inputCim"
                                name="cim"
                                value={cim}
                                placeholder="Digite o CIM"
                                onChange={(e) => handleCimChange(e.target.value)}
                            />
                            {cimError && (
                                <div className="invalid-feedback">
                                    {cimError}
                                </div>
                            )}
                        </FormGroup>
                        <br />
                        <FormGroup
                            label="CPF: *"
                            htmlFor="inputCpf"
                            error={cpfError}
                        >
                            <input
                                className={`form-control ${cpfError ? 'is-invalid' : ''}`}
                                type="text"
                                id="inputCpf"
                                name="cpf"
                                value={cpf}
                                placeholder="Digite o CPF"
                                onChange={(e) => handleCpfChange(e.target.value)}
                            />
                            {cpfError && (
                                <div className="invalid-feedback">
                                    {cpfError}
                                </div>
                            )}
                        </FormGroup>
                        <br />
                        <FormGroup label="Email: " htmlFor="inputEmail" error={emailError}>
                            <input
                                className={`form-control ${emailError ? 'is-invalid' : ''}`}
                                type="email"
                                id="inputEmail"
                                name="email"
                                value={email}
                                placeholder="Digite o email"
                                onChange={(e) => handleEmailChange(e.target.value)}
                                style={{ maxWidth: '300px' }}
                            />
                            {emailError && (
                                <div className="invalid-feedback">
                                    {emailError}
                                </div>
                            )}
                        </FormGroup>
                        <br />
                        <FormGroup label="Telefone Celular: " htmlFor="inputPhoneCell">
                            <textarea
                                className="form-control"
                                id="inputPhoneCell"
                                name="phoneCell"
                                value={phoneCell}
                                placeholder="Qual o Celular?"
                                onChange={handlePhoneCellChange}
                                style={{ height: 'auto', width: '50%' }} // Garantir 100% da largura
                                rows="1"
                            />
                        </FormGroup>
                        <br />
                        <FormField style={{ marginRight: '2px' }}> {/* Estilo inline para reduzir o espaço */}
                            <FormGroup label="Grau Simbólico: *" htmlFor="selectGrau">
                                <br />
                                <SelectMenu
                                    className={`form-control`}
                                    list={listDegree}
                                    value={lodgeDegree}
                                    onChange={handleDegreeChange}
                                />
                            </FormGroup>
                        </FormField>
                        <br />

                        <FormGroup label="Loja: " htmlFor="inputLodge">
                            <textarea
                                className="form-control"
                                id="lodgeInput1"
                                name="lodge"
                                value={lodge}
                                placeholder="Qual o nome da loja"
                                onChange={(e) => setLodge(e.target.value)}
                                style={{ height: 'auto', width: '100%' }} // Garantir 100% da largura
                                rows="3"
                            />
                        </FormGroup>

                        <br />

                        <FormGroup label="Potência: " htmlFor="inputLodgePotency">
                            <textarea
                                className="form-control"
                                id="inputLodgePotency"
                                name="lodgePotency"
                                value={lodgePotency}
                                placeholder="Qual a potência da loja"
                                onChange={(e) => setLodgePotency(e.target.value)}
                                style={{ height: 'auto', width: '100%' }} // Garantir 100% da largura
                                rows="2"
                            />
                        </FormGroup>
                        <br />
                        <FormGroup label="Oriente: " htmlFor="inputLodgeState">
                            <textarea
                                className="form-control"
                                id="inputLodgeState"
                                name="lodgeState"
                                value={lodgeState}
                                placeholder="Qual o oriente da loja"
                                onChange={(e) => setLodgeState(e.target.value)}
                                style={{ height: 'auto', width: '100%' }} // Garantir 100% da largura
                                rows="2"
                            />
                        </FormGroup>
                    </div>
                </Card>
            </div>
            <button onClick={register} className="btn btn-success">
                Cadastrar
            </button>
            {' '}
            <button onClick={finalize} className="btn btn-danger">
                Cancelar
            </button>
        </Container >
    );
}

export default VisitorRegister;
