//Visitors List.js:

import React, { useState, useEffect, useMemo, useContext } from "react";
import VisitorService from "../../app/service/visitorService";
import UserService from "../../app/service/userService.js";
import { messageSuccess, messageError } from "../../components/toastr";
import Card from "../../components/card";
import FormGroup from "../../components/form-group";
import Container from "../../components/container";
import { useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { AuthContext } from '../../security/AuthContext';
import VisitorDT from "./visitorDT";
import AttendanceService from "../../app/service/attendanceService.js";
import MemberService from "../../app/service/memberService";
import EventService from "../../app/service/eventService.js";
import { formatCPF } from '../../utils/cpfUtils.js';
import PRMTSService from '../../app/service/prmtsService';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import pdfMake from "pdfmake/build/pdfmake";
import { pdfHeader } from '../../components/pdfHeaderPdfMake.js'; // Certifique-se de ajustar o caminho corretamente
import pdfFonts from "pdfmake/build/vfs_fonts";
import customVfs from "../../components/customVfs";
import RolePermissionService from "../../app/service/rolePermissionService";
import * as Constant from '../../config/constants';


pdfMake.vfs = {
    ...pdfFonts.pdfMake.vfs,
    ...customVfs,
};

const VisitorList = () => {
    const { user, isAuthenticated } = useContext(AuthContext);
    const visitorService = useMemo(() => new VisitorService(), []);
    const memberService = useMemo(() => new MemberService(), []);
    const userService = useMemo(() => new UserService(), []);
    const attendanceService = useMemo(() => new AttendanceService(), []);
    const eventService = useMemo(() => new EventService(), []);
    const prmtsService = useMemo(() => new PRMTSService(), []);
    const rolePermissionService = useMemo(() => new RolePermissionService(), []);
    const [selectedVisitorId, setSelectedVisitorId] = useState(null);
    const [searchResults, setSearchResults] = useState([]);
    const [canView, setCanView] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [canPrint, setCanPrint] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [cim, setCim] = useState('');

    const handleSearch = async () => {
        const visitorFilter = {
            name: name,
            cim: cim
        };

        try {
            const response = await visitorService.findVisitors(visitorFilter);
            if (Array.isArray(response.data) && response.data.length > 0) {
                const sortedVisitors = response.data.sort((a, b) => a.name.localeCompare(b.name));
                setSearchResults(sortedVisitors);
            } else {
                setSearchResults([]);
                messageError("Nenhum resultado encontrado.");
            }
        } catch (error) {
            messageError(`Erro ao realizar a busca. <br /> ${error.response.data}`);
        }
    };



    const handleClear = () => {
        setName('');
        setCim('');
    }

    const handleRegisterNewVisitor = () => {
        navigate('/visitor-register');
        return;
    }

    const deleteVisitor = async (id) => {
        try {
            const response = await visitorService.deleteVisitor(id);
            if (response.status === 200) {
                messageSuccess("Visitante deletado com sucesso!");
                handleSearch(); // Atualiza a lista após a exclusão
            } else {
                messageError("Visitante não encontrado.");
            }
        } catch (error) {
            messageError("Erro ao deletar visitante.");
        }
    }

    const editVisitor = async (id) => {
        localStorage.setItem("visitor-edit", id)
        navigate("/visitor-register")
    }

    const confirmDelete = (id) => {
        setSelectedVisitorId(id);
        setVisibleModal(true);
    };

    const infoVisitor = async (id) => {
        pdfMake.vfs = pdfFonts.pdfMake.vfs; // Primeiro, atribui o vfs padrão
        pdfMake.vfs = { ...pdfMake.vfs, ...customVfs }; // Depois, mescla com o vfs personalizado

        pdfMake.fonts = {
            Roboto: {
                normal: "Roboto-Regular.ttf",
                bold: "Roboto-Medium.ttf",
                italics: "Roboto-Italic.ttf",
                bolditalics: "Roboto-MediumItalic.ttf",
            },
            GreatVibes: {
                normal: "GreatVibes-Regular.ttf",
                bold: "GreatVibes-Regular.ttf",
                italics: "GreatVibes-Regular.ttf",
                bolditalics: "GreatVibes-Regular.ttf",
            },
        };
        try {
            // Busca informações adicionais para o cabeçalho
            const responseDayTime = await prmtsService.getByType('DAYTIME-SESSION');
            const dayTimeSessionDescription = responseDayTime.data ? responseDayTime.data.description : '';

            // Importa o cabeçalho do pdfHeader.js
            const { headerContent, headerStyles } = pdfHeader(dayTimeSessionDescription);

            // Busca informações do visitante e histórico de presenças
            const response = await attendanceService.getByVisitorId(id);
            if (response && response.data && response.data.length > 0) {
                const attendances = response.data;
                const responseVisitor = await visitorService.getById(attendances[0].visitor);
                const visitor = responseVisitor.data;

                const safeValue = (value) => (value ? String(value) : "");

                const formatDate = (dateArray) => {
                    const [year, month, day] = dateArray.map(Number);
                    return `${String(day).padStart(2, '0')}/${String(month).padStart(2, '0')}/${year}`;
                };

                // Define Visitor Information Table
                const visitorInfoTable = {
                    table: {
                        widths: ['25%', '25%', '25%', '25%'],
                        body: [
                            [{ text: "Informações do Irmão Visitante", colSpan: 4, style: 'sectionHeader', alignment: 'center' }, {}, {}, {}],
                            [{ text: "CIM", bold: true }, safeValue(visitor.cim), { text: "Loja", bold: true }, safeValue(visitor.lodge)],
                            [{ text: "Potência", bold: true }, safeValue(visitor.lodgePotency), { text: "Oriente", bold: true }, safeValue(visitor.lodgeState)],
                            [{ text: "Nome", bold: true }, safeValue(visitor.name), { text: "CPF", bold: true }, formatCPF(visitor.cpf)],
                            [{ text: "Email", bold: true }, safeValue(visitor.email), { text: "Celular", bold: true }, safeValue(visitor.phoneCell)]
                        ]
                    },
                    layout: {
                        hLineWidth: () => 0.5,
                        vLineWidth: () => 0.5,
                    },
                    margin: [0, 10, 0, 10]
                };

                // Prepare Attendance History Table
                let attendanceHistory = [];
                for (const attendance of attendances) {
                    const responseEvent = await eventService.getById(attendance.event);
                    const event = responseEvent.data;
                    attendanceHistory.push({
                        date: event.dtStart,
                        description: safeValue(event.description),
                        degree: safeValue(event.degree),
                    });
                }

                attendanceHistory.sort((a, b) => {
                    const dateA = new Date(a.date[0], a.date[1] - 1, a.date[2]);
                    const dateB = new Date(b.date[0], b.date[1] - 1, b.date[2]);
                    return dateB - dateA;
                });

                const attendanceTable = {
                    table: {
                        widths: ['20%', '60%', '20%'],
                        body: [
                            [{ text: "Histórico de Presenças", colSpan: 3, style: 'sectionHeader', alignment: 'center' }, {}, {}],
                            [{ text: "Data", style: 'sectionHeader', alignment: 'center' }, { text: "Tipo de Sessão", style: 'sectionHeader', alignment: 'center' }, { text: "Grau", style: 'sectionHeader', alignment: 'center' }],
                            ...attendanceHistory.map(record => [
                                { text: formatDate(record.date), alignment: 'center' },
                                { text: record.description, alignment: 'center' },
                                { text: record.degree, alignment: 'center' }
                            ])
                        ]
                    },
                    layout: {
                        hLineWidth: () => 0.5,
                        vLineWidth: () => 0.5,
                    },
                    margin: [0, 10, 0, 10]
                };

                // Cria o documentDefinition com o cabeçalho e o restante do conteúdo
                const documentDefinition = {
                    content: [
                        ...headerContent,
                        { text: 'Informações do Irmão', style: 'headerSection' },
                        { text: visitor.name, style: 'headerSection' },
                        visitorInfoTable,
                        attendanceTable,
                    ],
                    styles: {
                        ...headerStyles, // Inclui os estilos do cabeçalho
                        headerSection: {
                            fontSize: 16,
                            bold: true,
                            alignment: 'center',
                            margin: [0, 10, 0, 10],
                            font: 'Roboto',
                        },
                        sectionHeader: {
                            bold: true,
                            fontSize: 12,
                            fillColor: '#eeeeee',
                            alignment: 'center',
                            font: 'Roboto',
                        },
                    },
                    defaultStyle: {
                        font: 'Roboto',
                    },
                };
                pdfMake.createPdf(documentDefinition).open();
            } else {
                messageError("Irmão não encontrado.");
            }
        } catch (error) {
            console.error(error);
            messageError("Erro ao gerar o PDF.");
        }
    };

    const handleConfirmation = (confirmed) => {
        setVisibleModal(false);

        if (confirmed) {
            deleteVisitor(selectedVisitorId);
        } else {
            setSelectedVisitorId(null);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                localStorage.removeItem('visitor-edit');

                if (isAuthenticated) {
                    if (user) {
                        if (user.role !== 1) {
                            const responseMember = await memberService.getById(user.member);
                            const responseCanEdit = await rolePermissionService.hasPermission(responseMember.data.lodgeRole, Constant.EDIT_VISITORS_INFO);
                            setCanEdit(responseCanEdit.data);
                            if (!responseCanEdit.data) {

                                const responseCanPrint = await rolePermissionService.hasPermission(responseMember.data.lodgeRole, Constant.PRINT_CERTIFICATE);
                                setCanPrint(responseCanPrint.data);

                                const responseCanView = await rolePermissionService.hasPermission(responseMember.data.lodgeRole, Constant.VIEW_SCREEN_VISITOR_LIST);
                                setCanView(responseCanView.data);

                            } else {
                                setCanView(true);
                                setCanPrint(true);

                            }
                        } else {
                            setCanEdit(true);
                            setCanPrint(true);
                            setCanView(true);

                        }
                    } else {
                        setCanView(false);
                        setCanEdit(false);
                        setCanPrint(false);
                    }
                } else {
                    messageError("Acesso negado. Procure um administrador.")
                    navigate('/home');
                }

                await handleSearch();
            } catch (error) {
                messageError('Erro ao buscar informações.');
            }
        };

        fetchData();
    }, [isAuthenticated, navigate, userService]);

    return (
        <Container>
            <Card title="Consultar IIr∴ Visitantes" >
                <div className="row">
                    <div className="col-lg-3">
                        <FormGroup label="Nome: " htmlFor="inputName">
                            <input
                                className="form-control"
                                type="text"
                                id="exampleInputName1"
                                name="name"
                                value={name}
                                placeholder="Digite o Nome"
                                onChange={(event) => { setName(event.target.value) }}
                            />
                        </FormGroup>
                        <FormGroup label="Cim: " htmlFor="inputCim">
                            <input
                                className="form-control"
                                type="text"
                                id="exampleInputCim1"
                                name="cim"
                                value={cim}
                                placeholder="Digite o CIM"
                                onChange={(event) => { setCim(event.target.value) }}
                            />
                        </FormGroup>
                    </div>
                </div>
                <br />
                <button className="btn btn-success" onClick={handleSearch}>Buscar</button>
                {' '}
                <button className="btn btn-danger" onClick={handleClear}>Limpar</button>
                {' '}
                {canEdit && (
                    <>
                        <button className="btn btn-info" onClick={handleRegisterNewVisitor}>Cadastrar novo visitante</button>
                    </>
                )}
                <br /><br />
                <div className="row">
                    <div className="visitorDt col-lg-12">
                        <VisitorDT
                            visitors={searchResults}
                            editVisitor={editVisitor}
                            deleteVisitor={confirmDelete}
                            infoVisitor={infoVisitor}
                            canPrint={canPrint}
                            canEdit={canEdit}
                        />
                    </div>
                </div>
                <Dialog
                    header="Confirmação"
                    visible={visibleModal}
                    style={{ width: '25vw' }}
                    onHide={() => handleConfirmation(false)}
                    modal={true}
                >
                    <p className="m-0">Deseja excluir o visitante?</p>
                    <br />
                    <div className="p-d-flex p-jc-end">
                        <button
                            className="btn btn-danger p-button-text"
                            onClick={() => handleConfirmation(true)}
                        >
                            Deletar
                        </button>
                        {' '}
                        <button
                            className="btn btn-secondary p-button-text"
                            onClick={() => handleConfirmation(false)}
                        >
                            Cancelar
                        </button>
                    </div>
                </Dialog>
            </Card>
        </Container>
    );
};

export default VisitorList;
