// TaxesService.js

import ApiService from '../apiservice';

class TaxesService extends ApiService {

    constructor() {
        super('/taxes');
    }

    getAll() {
        return this.get(`${this.apiurl}/all`);
    }

    new(tax) {
        return this.post(`${this.apiurl}/new`, tax);
    }

    update(tax) {
        // Monta DTO conforme o servidor espera em TaxesDTO
        const dto = {
            description: tax.description,
            taxGroup: tax.taxGroup,
            dayExpire: tax.dayExpire,
            startMonth: tax.startMonth,
            installments: tax.installments,
            // Garante que 'value' seja float
            value: typeof tax.value === 'number' ? tax.value : parseFloat(tax.value || 0),
            // Garante que 'year' seja inteiro
            year: typeof tax.year === 'number' ? tax.year : parseInt(tax.year, 10),
            // Garante que 'active' seja 'S' ou 'N' (caso seja boolean)
            active: (tax.active === true || tax.active === 'S') ? 'S'
                : (tax.active === false || tax.active === 'N') ? 'N'
                    : tax.active
        };

        // Chama PUT /taxes/:id
        return this.put(`${this.apiurl}/${tax.id}`, dto);
    }

    getByYear(year) {
        return this.get(`${this.apiurl}?year=${year}`);
    }

}

export default TaxesService;
